@import 'src/assets/sass/variables';

@media (max-width: 575.98px) {
    .box-background {
        padding: 0 !important;
        background-position: right top !important;
        align-content: flex-end !important;

        & .boxLogin {
            width: 100% !important;
            height: 75%;
            border-radius: 20px 20px 0 0 !important;
            padding: 5% 5%;
            align-self: flex-end;
        }

        & .spanLogin {
            background-color: #fefefe;
            color: var(--c-nordsol);
            padding: 5px 0 !important;
            width: 100% !important;
            margin: 0 !important;
            position: relative !important;
        }
    }

    .display-image {
        height: 100vw !important;
    }

    .text-bg {
        &::after {
            font-size: 13rem !important;
        }
    }

    .n-height {
        height: 180px !important;
    }

    .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
        display: none;
    }

    .p-steps .p-steps-item.p-highlight .p-steps-title {
        display: contents;
    }

}

@media (min-width: 576px) and (max-width: 767.98) {
    .text-bg {
        &::after {
            font-size: 15rem !important;
        }
    }

    .n-height {
        height: 200px !important;
    }
}

@media (max-width: 767.98px) {
    .d-sm-label-none {
        & .p-button-label {
            display: none !important;
        }
    }

    .boxLogin {
        width: 70% !important;
    }

    .box-details {
        border-radius: 15px 15px 0 0 !important;
    }

    .details-vehicles {
        height: auto !important;
    }
}

@media (min-width: 768px) {
    .boxLogin {
        width: 60% !important;
    }

    .n-height {
        height: 220px !important;
    }

    .text-bg {
        &::after {
            font-size: 18rem !important;
        }
    }
}

@media (min-width: 992px) {
    .boxLogin {
        width: 50% !important;
    }

    .n-height {
        height: 300px !important;
    }

    .text-bg {
        &::after {
            font-size: 24rem !important;
        }
    }
}

@media (min-width: 1279.98px) {
    .boxLogin {
        width: 45% !important;
    }

}

@media (max-width: 1399.98px) {}

@media (min-width: 1400px) {
    .boxLogin {
        width: 40% !important;
    }
}