.logo-dark {
    width: 200px;
    height: 130px;
    background-image: url('../images/logo_dark.png');
    background-repeat: no-repeat;
    background-size: contain;
    align-self: center;
    background-position: center;
}

.logo-nord {
    width: 200px;
    height: 130px;
    background-image: url('../images/logo-nordsol-erro.png');
    background-repeat: no-repeat;
    background-size: contain;
    align-self: center;
    background-position: center;
}