.p-breadcrumb {
    background: none!important;
    border: none!important;
    border-radius: 0!important;
}

.custom-marker {
    display: flex;
    width: 2rem!important;
    height: 2rem!important;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}

::ng-deep {
    .p-timeline-event-content,
    .p-timeline-event-opposite {
      line-height: 1;
    }
  }
