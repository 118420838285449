.default-card-photos {
    width: 100%;
    height: 160px;
    background-color: #AEC8ED;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px 5px 0 0;
}

.card-photos {
    height: 160px;
    background-size: cover;
    background-position: center center;
    border-radius: 10px 10px 0px 0px;
    img {
        width: 100%;
    }
}

.photo-header {
    width: 120px;
    height: 96px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &--mini {
        width: 30px;
        height: 30px;
    }

    &--base {
        width: 120px;
        height: 96px;
    }

    &--maxi {
        width: 100px!important;
        height: 100px!important;
    }
}

.default-position {
    width: 100%;
    height: 180px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px 5px 0 0;
}