@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
* {
  box-sizing: border-box;
}

.p-component {
  font-weight: normal;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.7490196078) !important;
  border-radius: 4px 4px 0 0;
  transition-duration: 0.2s;
  z-index: 999999 !important;
}

.p-component-overlay-enter {
  display: none;
}

.p-image-action.p-link {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: white !important;
  margin: 1rem !important;
}

.p-inputnumber:enabled:hover {
  border-color: black;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.38;
}

.icon-delete {
  background-color: var(--c-danger);
  color: white;
  border-radius: 2rem;
  padding: 0.5rem;
}

.icon-open {
  background-color: var(--c-success);
  color: white;
  border-radius: 2rem;
  padding: 0.5rem;
}

.icon-detail {
  background-color: var(--c-nordsol-yellow);
  color: white;
  border-radius: 2rem;
  padding: 0.5rem;
}

.icon-edit {
  background-color: var(--c-nordsol-blue);
  color: white;
  border-radius: 2rem;
  padding: 0.5rem;
}

.p-error {
  color: var(--c-danger);
}

.p-success {
  color: var(--c-success);
}

.p-text-secondary {
  color: var(--c-nordsol700);
}

.pi {
  font-size: 1rem;
}

.p-link {
  font-size: 1rem;
  font-family: Lato, sans-serif;
  border-radius: 4px;
}

.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-autocomplete .p-autocomplete-loader {
  right: 0.75rem;
}

.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 3.5rem;
}

.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.375rem 0.75rem;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: var(--c-nordsol);
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.375rem 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  padding: 0;
  margin: 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: rgba(51, 108, 204, 0.12);
  color: var(--c-nordsol);
  border-radius: 4px;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: var(--c-danger);
}

.p-autocomplete-panel {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.p-autocomplete-panel .p-autocomplete-items:not(.p-autocomplete-virtualscroll) {
  padding: 0;
}

.p-autocomplete-panel .p-autocomplete-items.p-autocomplete-virtualscroll .cdk-virtual-scroll-content-wrapper {
  padding: 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.75rem 0.75rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  transition: none;
  border-radius: 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.04);
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: var(--c-nordsol);
  background: rgba(25, 50, 90, 0.12);
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
  padding: 0.75rem 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  margin: 0;
  padding: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  background: #ffffff;
  font-weight: 400;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: var(--c-danger);
}

.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}

.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  font-weight: 500;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #19325A;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}

.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.p-datepicker table th {
  padding: 0.5rem;
}

.p-datepicker table th > span {
  width: 2.25rem;
  height: 2.25rem;
}

.p-datepicker table td {
  padding: 0.5rem;
}

.p-datepicker table td > span {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  transition: none;
  border: 1px solid transparent;
}

.p-datepicker table td > span.p-highlight {
  color: #19325A;
  background: rgba(25, 50, 90, 0.12);
}

.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-datepicker table td.p-datepicker-today > span {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-color: black;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #19325A;
  background: rgba(25, 50, 90, 0.12);
}

.p-datepicker .p-datepicker-buttonbar {
  padding: 0.75rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}

.p-datepicker .p-timepicker {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0.5rem;
}

.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datepicker .p-timepicker button:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}

.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}

.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}

.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}

.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}

.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: none;
  border-radius: 4px;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #19325A;
  background: rgba(25, 50, 90, 0.12);
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }
}
.p-cascadeselect {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}

.p-cascadeselect:not(.p-disabled):hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.p-cascadeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #19325A;
}

.p-cascadeselect .p-cascadeselect-label {
  background: transparent;
  border: 0 none;
  padding: 0.75rem 0.75rem;
}

.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}

.p-cascadeselect .p-cascadeselect-trigger {
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  width: 2.75rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-cascadeselect-panel {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.p-cascadeselect-panel .p-cascadeselect-items {
  padding: 0;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: 0;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  transition: none;
  border-radius: 0;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
  padding: 0.75rem 0.75rem;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: #19325A;
  background: rgba(25, 50, 90, 0.12);
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.04);
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
  font-size: 0.875rem;
}

p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
  border-color: var(--c-danger);
}

.p-checkbox {
  width: 18px;
  height: 18px;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #757575;
  background: #ffffff;
  width: 18px;
  height: 18px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #19325A;
  background: #19325A;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #19325A;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #19325A;
  background: #19325A;
  color: #ffffff;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: var(--c-danger);
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #f5f5f5;
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #19325A;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #ececec;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #19325A;
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container {
  padding: 0.375rem 0.75rem;
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #19325A;
}

.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: rgba(25, 50, 90, 0.12);
  color: #19325A;
  border-radius: 4px;
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.375rem 0;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  padding: 0;
  margin: 0;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: var;
}

.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #323232;
  border-color: #191919;
}

.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #ffffff;
}

.p-colorpicker-overlay-panel {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.p-dropdown {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #19325A;
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.75rem;
}

.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}

.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  width: 2.75rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-dropdown .p-dropdown-clear-icon {
  color: rgba(0, 0, 0, 0.6);
  right: 2.75rem;
}

.p-dropdown-panel {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.p-dropdown-panel .p-dropdown-header {
  padding: 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}

.p-dropdown-panel .p-dropdown-items:not(.p-dropdown-virtualscroll) {
  padding: 0;
}

.p-dropdown-panel .p-dropdown-items.p-dropdown-virtualscroll .cdk-virtual-scroll-content-wrapper {
  padding: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.75rem 0.75rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  transition: none;
  border-radius: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #19325A;
  background: rgba(25, 50, 90, 0.12);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.04);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.75rem 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  background: #ffffff;
  font-weight: 400;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: var(--c-danger);
}

.p-input-filled .p-dropdown {
  background: #f5f5f5;
}

.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #ececec;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #dcdcdc;
}

.p-editor-container .p-editor-toolbar {
  background: #ffffff;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #e0e0e0;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.6);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: rgba(0, 0, 0, 0.6);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: rgba(0, 0, 0, 0.6);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: rgba(0, 0, 0, 0.87);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: rgba(0, 0, 0, 0.87);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 0;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: rgba(0, 0, 0, 0.87);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.04);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 0.75rem 0.75rem;
}

.p-editor-container .p-editor-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #e0e0e0;
}

.p-editor-container .p-editor-content .ql-editor {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: rgba(0, 0, 0, 0.87);
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #19325A;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #19325A;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #19325A;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #19325A;
}

.p-inputgroup-addon {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.6);
  border-top: 1px solid rgba(0, 0, 0, 0.38);
  border-left: 1px solid rgba(0, 0, 0, 0.38);
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
  padding: 0.75rem 0.75rem;
  min-width: 2.75rem;
}

.p-inputgroup-addon:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.38);
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}

.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}

.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}

.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}

.p-fluid .p-inputgroup .p-inputtext {
  width: 100% !important;
}

.p-fluid.p-inputgroup .p-inputtext {
  width: 100% !important;
}

.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.75rem;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: var(--c-danger);
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: var(--c-danger) !important;
}

.p-inputswitch {
  width: 42px;
  height: 24px;
  border-radius: 50%;
}
.p-inputswitch .p-inputswitch-slider {
  background: var(--c-disabled);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 1rem;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 22px;
  height: 22px;
  left: 1px;
  margin-top: -0.75rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}
.p-inputswitch.p-focus {
  background: var(--c-empty-text);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--c-empty-text);
}

.nordsol .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--c-nordsol900);
}
.nordsol .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--c-nordsol);
}

.nordsol2 .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--c-nordsol2900);
}
.nordsol2 .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--c-nordsol2);
}

.nordsol-green .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--c-nordsol-green800);
}
.nordsol-green .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--c-nordsol-green);
}

.nordsol3 .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--c-nordsol3700);
}
.nordsol3 .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--c-nordsol3);
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: var(--c-danger);
}

.p-inputtext {
  font-size: 1rem;
  color: var(--c-font-dark);
  background: white;
  padding: 0.75rem 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  appearance: none;
  border-radius: 1rem;
}

.p-inputtext:enabled:hover {
  border-color: black;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #19325A;
}

.p-inputtext.ng-dirty.ng-invalid {
  border-color: var(--c-danger);
}

.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}

.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem;
}

.p-float-label > label {
  left: 0.75rem;
  margin-top: -0.5rem !important;
  color: rgba(0, 0, 0, 0.6);
  transition-duration: 0.2s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: var(--c-danger);
  margin-top: -0.5rem !important;
}

.p-input-icon-left > i:first-of-type {
  left: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem;
}

.p-input-icon-left.p-float-label > label {
  left: 2.5rem;
}

.p-input-icon-right > i:last-of-type {
  right: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}

.p-input-icon-right > .p-inputtext {
  padding-right: 2.5rem;
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

:-moz-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

::-moz-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.p-input-filled .p-inputtext {
  background-color: transparent !important;
}

.p-input-filled .p-inputtext:enabled:hover {
  background-color: transparent !important;
}

.p-input-filled .p-inputtext:enabled:focus {
  background-color: transparent !important;
}

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem;
}

.p-listbox {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.p-listbox .p-listbox-header {
  padding: 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.75rem;
}

.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}

.p-listbox .p-listbox-header .p-checkbox {
  margin-right: 0.5rem;
}

.p-listbox .p-listbox-list {
  padding: 0;
}

.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.75rem 0.75rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  transition: none;
  border-radius: 0;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #19325A;
  background: rgba(25, 50, 90, 0.12);
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  margin-right: 0.5rem;
}

.p-listbox .p-listbox-list .p-listbox-item-group {
  margin: 0;
  padding: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  background: #ffffff;
  font-weight: 400;
}

.p-listbox .p-listbox-list .p-listbox-empty-message {
  padding: 0.75rem 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.04);
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: var(--c-danger);
}

.p-multiselect {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #19325A;
}

.p-multiselect .p-multiselect-label {
  padding: 0.75rem 0.75rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: rgba(25, 50, 90, 0.12);
  color: #19325A;
  border-radius: 4px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}

.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  width: 2.75rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.375rem 0.75rem;
}

.p-multiselect-panel {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 1.75rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}

.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-multiselect-panel .p-multiselect-items:not(.p-multiselect-virtualscroll) {
  padding: 0;
}

.p-multiselect-panel .p-multiselect-items.p-multiselect-virtualscroll .cdk-virtual-scroll-content-wrapper {
  padding: 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.75rem 0.75rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  transition: none;
  border-radius: 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #19325A;
  background: rgba(25, 50, 90, 0.12);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.04);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  background: #ffffff;
  font-weight: 400;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.75rem 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: var(--c-danger);
}

.p-input-filled .p-multiselect {
  /* background: #f5f5f5; */
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
  /* background-color: #ececec; */
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  /* background-color: #dcdcdc; */
}

.p-password.p-invalid.p-component > .p-inputtext {
  border-color: var(--c-danger);
}

.p-password-panel {
  padding: 0.75rem;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
  background: rgba(25, 50, 90, 0.32);
}

.p-password-panel .p-password-meter .p-password-strength.weak {
  background: var(--c-danger);
}

.p-password-panel .p-password-meter .p-password-strength.medium {
  background: var(--c-nordsol-yellow);
}

.p-password-panel .p-password-meter .p-password-strength.strong {
  background: var(--c-success);
}

.p-radiobutton {
  width: 20px;
  height: 20px;
}

.p-radiobutton .p-radiobutton-box {
  border: 2px solid #757575;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 50%;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #19325A;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 10px;
  height: 10px;
  transition-duration: 0.2s;
  background-color: #19325A;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #19325A;
  background: #ffffff;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #19325A;
  background: #ffffff;
  color: #19325A;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: var(--c-danger);
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #f5f5f5;
}

.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #ececec;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #ffffff;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #ffffff;
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-rating .p-rating-icon {
  color: #336CCC;
  margin-left: 0.5rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  font-size: 1rem;
}

.p-rating .p-rating-icon.p-rating-cancel {
  color: var(--c-danger);
}

.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}

.p-rating .p-rating-icon.pi-star {
  color: #336CCC;
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #19325A;
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: var(--c-danger);
}

.p-selectbutton .p-button {
  padding: 0.643rem 0.75rem;
  box-shadow: none !important;
  background: var(--c-font-light);
  border: 1px solid var(--c-border-neutral);
  color: var(--c-nordsol);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: var(--c-nordsol);
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: var(--c-nordsol100);
  border-color: var(--c-border-neutral);
}
.p-selectbutton .p-highlight {
  background: var(--c-nordsol);
  color: var(--c-font-light);
  border-color: var(--c-nordsol);
}
.p-selectbutton .p-highlight .p-button-icon-left,
.p-selectbutton .p-highlight .p-button-icon-right {
  color: var(--c-font-light);
}
.p-selectbutton .p-highlight:hover {
  border-color: var(--c-nordsol500);
}

.nordsol2 .p-selectbutton .p-button {
  background: var(--c-font-light);
  border: 1px solid var(--c-border-neutral);
  color: var(--c-nordsol2);
}
.nordsol2 .p-selectbutton .p-button .p-button-icon-left,
.nordsol2 .p-selectbutton .p-button .p-button-icon-right {
  color: var(--c-nordsol2);
}
.nordsol2 .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: var(--c-nordsol250);
  border-color: var(--c-border-neutral);
}
.nordsol2 .p-selectbutton .p-highlight {
  background: var(--c-nordsol2);
  color: var(--c-font-light);
  border-color: var(--c-nordsol2);
}
.nordsol2 .p-selectbutton .p-highlight .p-button-icon-left,
.nordsol2 .p-selectbutton .p-highlight .p-button-icon-right {
  color: var(--c-font-light);
}
.nordsol2 .p-selectbutton .p-highlight:hover {
  border-color: var(--c-nordsol2500);
}

.nordsol-green .p-selectbutton .p-button {
  background: var(--c-font-light);
  border: 1px solid var(--c-border-neutral);
  color: var(--c-nordsol-green800);
}
.nordsol-green .p-selectbutton .p-button .p-button-icon-left,
.nordsol-green .p-selectbutton .p-button .p-button-icon-right {
  color: var(--c-nordsol-green800);
}
.nordsol-green .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: var(--c-nordsol-green100);
  border-color: var(--c-border-neutral);
}
.nordsol-green .p-selectbutton .p-highlight {
  background: var(--c-nordsol-green);
  color: var(--c-nordsol-green900);
  border-color: var(--c-nordsol-green);
}
.nordsol-green .p-selectbutton .p-highlight .p-button-icon-left,
.nordsol-green .p-selectbutton .p-highlight .p-button-icon-right {
  color: var(--c-nordsol-green900);
}
.nordsol-green .p-selectbutton .p-highlight:hover {
  border-color: var(--c-nordsol-green500);
}

.nordsol3 .p-selectbutton .p-button {
  background: var(--c-font-light);
  border: 1px solid var(--c-border-neutral);
  color: var(--c-nordsol3700);
}
.nordsol3 .p-selectbutton .p-button .p-button-icon-left,
.nordsol3 .p-selectbutton .p-button .p-button-icon-right {
  color: var(--c-nordsol3700);
}
.nordsol3 .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: var(--c-nordsol3100);
  border-color: var(--c-border-neutral);
}
.nordsol3 .p-selectbutton .p-highlight {
  background: var(--c-nordsol3);
  color: var(--c-font-light);
  border-color: var(--c-nordsol3);
}
.nordsol3 .p-selectbutton .p-highlight .p-button-icon-left,
.nordsol3 .p-selectbutton .p-highlight .p-button-icon-right {
  color: var(--c-nordsol3);
}
.nordsol3 .p-selectbutton .p-highlight:hover {
  border-color: var(--c-nordsol3700);
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: var(--c-danger);
}

.p-slider {
  background: #c1c1c1;
  border: 0 none;
  border-radius: 4px;
}

.p-slider.p-slider-horizontal {
  height: 2px;
}

.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -10px;
  margin-left: -10px;
}

.p-slider.p-slider-vertical {
  width: 2px;
}

.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -10px;
  margin-bottom: -10px;
}

.p-slider .p-slider-handle {
  height: 20px;
  width: 20px;
  background: #19325A;
  border: 0 none;
  border-radius: 50%;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-slider .p-slider-range {
  background: #19325A;
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #19325A;
  border-color: 0 none;
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1), left 0.2s;
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.2s;
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1), bottom 0.2s;
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.2s;
}

.p-togglebutton.p-button {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: rgba(0, 0, 0, 0.6);
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #f6f6f6;
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: rgba(0, 0, 0, 0.6);
}

.p-togglebutton.p-button.p-highlight {
  background: #e0e0e1;
  border-color: #e0e0e1;
  color: rgba(0, 0, 0, 0.87);
}

.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: rgba(0, 0, 0, 0.6);
}

.p-togglebutton.p-button.p-highlight:hover {
  background: #d9d8d9;
  border-color: #d9d8d9;
  color: rgba(0, 0, 0, 0.87);
}

.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: rgba(0, 0, 0, 0.6);
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: var(--c-danger);
}

.p-treeselect {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}

.p-treeselect:not(.p-disabled):hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #19325A;
}

.p-treeselect .p-treeselect-label {
  padding: 0.75rem 0.75rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.p-treeselect .p-treeselect-label.p-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 16px;
}

.p-treeselect .p-treeselect-trigger {
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  width: 2.75rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-treeselect.p-invalid.p-component {
  border-color: var(--c-danger);
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: 0.375rem 0.75rem;
}

.p-treeselect-panel {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  padding: 0.75rem 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
}

.p-input-filled .p-treeselect {
  background: #f5f5f5;
}

.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background-color: #ececec;
}

.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: #dcdcdc;
}

.p-button {
  color: var(--c-font-light);
  border: 0 none;
  padding: 12px 40px;
  font-weight: 700;
  font-family: Lato, sans-serif;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 1rem;
  box-shadow: var(--button-shadow);
  background: var(--c-nordsol);
}
.p-button:enabled:hover {
  background: var(--c-nordsol700);
  color: var(--c-font-light);
  border-color: transparent;
}
.p-button:enabled:active {
  background: var(--c-nordsol900);
  color: var(--c-font-light);
  border-color: transparent;
}
.p-button.p-button-outlined {
  background-color: var(--c-background-light);
  color: var(--c-nordsol);
  border: 1px solid var(--c-nordsol);
}
.p-button.p-button-outlined:enabled:hover {
  background: var(--c-nordsol100);
  color: var(--c-nordsol);
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active {
  background: var(--c-background-light);
  color: var(--c-nordsol600);
  border: 1px solid var(--c-nordsol600);
}
.p-button.p-button-text {
  background-color: var(--c-background-light);
  color: var(--c-nordsol);
  border: 1px solid transparent;
}
.p-button.p-button-text:enabled:hover {
  background: var(--c-nordsol100);
  color: var(--c-nordsol);
  border: 1px solid;
}
.p-button.p-button-text:enabled:active {
  background: var(--c-background-light);
  color: var(--c-nordsol600);
  border: 1px solid transparent;
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button.p-button-text {
  box-shadow: none;
}
.p-button.p-button-nordsol2 {
  background: var(--c-nordsol2);
}
.p-button.p-button-nordsol2:enabled:hover {
  background: var(--c-nordsol2900);
  color: var(--c-font-light);
  border-color: transparent;
}
.p-button.p-button-nordsol2:enabled:active {
  background: var(--c-nordsol2400);
  color: var(--c-font-light);
  border-color: var(--c-nordsol2900);
}
.p-button.p-button-nordsol2.p-button-outlined {
  background-color: var(--c-background-light);
  color: var(--c-nordsol2);
  border: 1px solid var(--c-nordsol2);
}
.p-button.p-button-nordsol2.p-button-outlined:enabled:hover {
  background: var(--c-nordsol2100);
  color: var(--c-nordsol2);
  border: 1px solid;
}
.p-button.p-button-nordsol2.p-button-outlined:enabled:active {
  background: var(--c-background-light);
  color: var(--c-nordsol2400);
  border: 1px solid var(--c-nordsol2400);
}
.p-button.p-button-nordsol-green {
  background: var(--c-nordsol-green);
  color: var(--c-nordsol-green800);
}
.p-button.p-button-nordsol-green:enabled:hover {
  background: var(--c-nordsol-green400);
  color: var(--c-nordsol-green800);
  border-color: transparent;
}
.p-button.p-button-nordsol-green:enabled:active {
  background: var(--c-nordsol-green100);
  color: var(--c-nordsol-green800);
  border-color: var(--c-nordsol-green900);
}
.p-button.p-button-nordsol-green.p-button-outlined {
  background-color: var(--c-background-light);
  color: var(--c-nordsol-green800);
  border: 1px solid var(--c-nordsol-green800);
}
.p-button.p-button-nordsol-green.p-button-outlined:enabled:hover {
  background: var(--c-nordsol-green50);
  color: var(--c-nordsol-green800);
  border: 1px solid;
}
.p-button.p-button-nordsol-green.p-button-outlined:enabled:active {
  background: var(--c-background-light);
  color: var(--c-nordsol-green500);
  border: 1px solid var(--c-nordsol-green500);
}
.p-button.p-button-nordsol3 {
  background: var(--c-nordsol3);
  color: var(--c-font-light);
}
.p-button.p-button-nordsol3:enabled:hover {
  background: var(--c-nordsol2800);
  color: var(--c-font-light);
  border-color: transparent;
}
.p-button.p-button-nordsol3:enabled:active {
  background: var(--c-nordsol3200);
  color: var(--c-font-light);
  border-color: var(--c-nordsol3900);
}
.p-button.p-button-nordsol3.p-button-outlined {
  background-color: var(--c-background-light);
  color: var(--c-nordsol3);
  border: 1px solid var(--c-nordsol3);
}
.p-button.p-button-nordsol3.p-button-outlined:enabled:hover {
  background: var(--c-nordsol350);
  color: var(--c-nordsol3);
  border: 1px solid;
}
.p-button.p-button-nordsol3.p-button-outlined:enabled:active {
  background: var(--c-background-light);
  color: var(--c-nordsol3700);
  border: 1px solid var(--c-nordsol3700);
}
.p-button.p-button-danger {
  background: var(--c-danger);
  color: var(--c-font-light);
}
.p-button.p-button-danger:enabled:hover {
  background: #8D1919;
  color: var(--c-font-light);
  border-color: transparent;
}
.p-button.p-button-danger:enabled:active {
  background: #E78484;
  color: var(--c-font-light);
  border-color: var(--c-danger);
}
.p-button.p-button-danger.p-button-outlined {
  background-color: var(--c-background-light);
  color: var(--c-danger);
  border: 1px solid var(--c-danger);
}
.p-button.p-button-danger.p-button-outlined:enabled:hover {
  background: #F6D5D5;
  color: var(--c-danger);
  border: 1px solid var(--c-danger);
}
.p-button.p-button-danger.p-button-outlined:enabled:active {
  background: var(--c-background-light);
  color: #E48181;
  border: 1px solid #E48181;
}
.p-button.p-button-success {
  background: var(--c-success);
  color: var(--c-font-light);
}
.p-button.p-button-success:enabled:hover {
  background: #4d903c;
  color: var(--c-font-light);
  border-color: transparent;
}
.p-button.p-button-success:enabled:active {
  background: #76af67;
  color: var(--c-font-light);
  border-color: var(--c-success);
}
.p-button.p-button-success.p-button-outlined {
  background-color: var(--c-background-light);
  color: var(--c-success);
  border: 1px solid var(--c-success);
}
.p-button.p-button-success.p-button-outlined:enabled:hover {
  background: var(--bg-success);
  color: var(--c-success);
  border: 1px solid var(--c-success);
}
.p-button.p-button-success.p-button-outlined:enabled:active {
  background: var(--c-background-light);
  color: #76af67;
  border: 1px solid #76af67;
}
.p-button.p-button-sm {
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 6px;
}

@media screen and (max-width: 575.98px) {
  .p-button-mobile {
    width: 100%;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .p-button-mobile {
    width: 100%;
  }
}
.p-button.p-button-outlined.p-button-plain {
  color: rgba(0, 0, 0, 0.6);
  border-color: rgba(0, 0, 0, 0.6);
}

.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.6);
}

.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: rgba(0, 0, 0, 0.16);
  color: rgba(0, 0, 0, 0.6);
}

.p-button.p-button-text {
  background-color: transparent;
  color: #19325A;
  border-color: transparent;
}

.p-button.p-button-text:enabled:hover {
  background: rgba(25, 50, 90, 0.04);
  color: #19325A;
  border-color: transparent;
}

.p-button.p-button-text:enabled:active {
  background: rgba(25, 50, 90, 0.16);
  color: #19325A;
  border-color: transparent;
}

.p-button.p-button-text.p-button-plain {
  color: rgba(0, 0, 0, 0.6);
}

.p-button.p-button-text.p-button-plain:enabled:hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.6);
}

.p-button.p-button-text.p-button-plain:enabled:active {
  background: rgba(0, 0, 0, 0.16);
  color: rgba(0, 0, 0, 0.6);
}

.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: #19325A;
  background-color: #ffffff;
}

.p-button.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-button.p-button-rounded {
  border-radius: 2rem;
}

.p-button.p-button-icon-only {
  width: 2.75rem;
  padding: 0.643rem;
}

.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}

.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.75rem;
}

.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}

.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.80375rem 0.9375rem;
}

.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}

.p-button.p-button-loading-label-only .p-button-label {
  margin-left: 0.5rem;
}

.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin-right: 0;
}

.p-fluid .p-button {
  width: 100%;
}

.p-fluid .p-button-icon-only {
  width: 2.75rem;
}

.p-fluid .p-buttonset {
  display: flex;
}

.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-link {
  color: #19325A;
  background: transparent;
  border: transparent;
}

.p-button.p-button-link:enabled:hover {
  background: transparent;
  color: #19325A;
  border-color: transparent;
}

.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}

.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: none;
  border-color: transparent;
}

.p-button.p-button-link:enabled:active {
  background: transparent;
  color: #19325A;
  border-color: transparent;
}

.p-button-secondary.p-button-link {
  color: #336CCC;
  background: transparent;
  border: transparent;
}

.p-button-secondary.p-button-link:enabled:hover {
  background: transparent;
  color: #336CCC;
  border-color: transparent;
}

.p-button-secondary.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}

.p-button-secondary.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: none;
  border-color: transparent;
}

.p-button-secondary.p-button-link:enabled:active {
  background: transparent;
  color: #336CCC;
  border-color: transparent;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0.5rem;
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}

.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-carousel .p-carousel-indicators {
  padding: 0.5rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #dcdcdc;
  width: 2rem;
  height: 0.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #ececec;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: rgba(25, 50, 90, 0.12);
  color: #19325A;
}

.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-datatable .p-datatable-header {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 0.75rem 0.75rem;
  font-weight: 500;
}

.p-datatable .p-datatable-footer {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 0.75rem 0.75rem;
  font-weight: 500;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0.75rem 0.75rem;
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  transition: none;
}

.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 0.75rem 0.75rem;
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #19325A;
  background: rgba(25, 50, 90, 0.12);
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-datatable .p-sortable-column.p-highlight {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: rgba(0, 0, 0, 0.87);
}

.p-datatable .p-sortable-column.p-highlight:hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: rgba(0, 0, 0, 0.87);
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none;
  outline: 0 none;
}

.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  transition: none;
  outline-color: transparent;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 0.75rem 0.75rem;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: rgba(25, 50, 90, 0.12);
  color: #19325A;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 rgba(25, 50, 90, 0.12);
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 rgba(25, 50, 90, 0.12);
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.p-datatable .p-column-resizer-helper {
  background: #19325A;
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #ffffff;
}

.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
  color: #19325A;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.02);
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: rgba(25, 50, 90, 0.12);
  color: #19325A;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #19325A;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #19325A;
}

.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.375rem 0.375rem;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.375rem 0.375rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.375rem 0.375rem;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.375rem 0.375rem;
}

.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.375rem 0.375rem;
}

.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 0.9375rem 0.9375rem;
}

.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 0.9375rem 0.9375rem;
}

.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 0.9375rem 0.9375rem;
}

.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 0.9375rem 0.9375rem;
}

.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 0.9375rem 0.9375rem;
}

.p-dataview .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-dataview .p-dataview-header {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 0.75rem 0.75rem;
  font-weight: 500;
}

.p-dataview .p-dataview-content {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  padding: 0.75rem;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid rgba(0, 0, 0, 0.12);
  border-width: 0 0 1px 0;
}

.p-dataview .p-dataview-footer {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 0.75rem 0.75rem;
  font-weight: 500;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}

.p-dataview .p-dataview-emptymessage {
  padding: 0.75rem;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-column-filter-menu-button:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}

.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.6);
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: rgba(25, 50, 90, 0.12);
  color: #19325A;
}

.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-column-filter-clear-button:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}

.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-column-filter-overlay {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  min-width: 12.5rem;
}

.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.75rem 0.75rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  transition: none;
  border-radius: 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: #19325A;
  background: rgba(25, 50, 90, 0.12);
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.04);
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.75rem 0.75rem;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 0.75rem;
}

.fc .fc-view-container th {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.fc .fc-view-container td.fc-widget-content {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.fc .fc-view-container td.fc-head-container {
  border: 1px solid #e0e0e0;
}

.fc .fc-view-container .fc-row {
  border-right: 1px solid #e0e0e0;
}

.fc .fc-view-container .fc-event {
  background: rgba(25, 50, 90, 0.12);
  border: 1px solid rgba(25, 50, 90, 0.12);
  color: #19325A;
}

.fc .fc-view-container .fc-divider {
  background: #ffffff;
  border: 1px solid #e0e0e0;
}

.fc .fc-toolbar .fc-button {
  color: #ffffff;
  background: #19325A;
  border: 0 none;
  font-size: 1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.fc .fc-toolbar .fc-button:enabled:hover {
  background: rgba(25, 50, 90, 0.92);
  color: #ffffff;
  border-color: transparent;
}

.fc .fc-toolbar .fc-button:enabled:active {
  background: rgba(25, 50, 90, 0.68);
  color: #ffffff;
  border-color: transparent;
}

.fc .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.fc .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}

.fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "\e900";
}

.fc .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}

.fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "\e901";
}

.fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #f6f6f6;
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #e0e0e1;
  border-color: #e0e0e1;
  color: rgba(0, 0, 0, 0.87);
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #d9d8d9;
  border-color: #d9d8d9;
  color: rgba(0, 0, 0, 0.87);
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:focus,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button:focus,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  z-index: 1;
}

.fc .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}

.fc .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.fc .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-orderlist .p-orderlist-controls {
  padding: 0.75rem;
}

.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}

.p-orderlist .p-orderlist-header {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e0e0e0;
  padding: 0.75rem;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-orderlist .p-orderlist-header .p-orderlist-title {
  font-weight: 500;
}

.p-orderlist .p-orderlist-filter-container {
  padding: 0.75rem;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-bottom: 0 none;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 1.75rem;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}

.p-orderlist .p-orderlist-list {
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.75rem 0.75rem;
  margin: 0;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  transition: none;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #19325A;
  background: rgba(25, 50, 90, 0.12);
}

.p-orderlist .p-orderlist-list .p-orderlist-empty-message {
  padding: 0.75rem 0.75rem;
  color: rgba(0, 0, 0, 0.87);
}

.p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.p-orderlist-item.cdk-drag-preview {
  padding: 0.75rem 0.75rem;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  margin: 0;
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: rgba(25, 50, 90, 0.12);
  color: #19325A;
}

.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: rgba(30, 39, 86, 0.12);
}

.p-organizationchart .p-organizationchart-line-down {
  background: rgba(0, 0, 0, 0.12);
}

.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #e0e0e0;
  border-color: rgba(0, 0, 0, 0.12);
}

.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #e0e0e0;
  border-color: rgba(0, 0, 0, 0.12);
}

.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.75rem;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-paginator {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: solid #e4e4e4;
  border-width: 0;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: rgba(0, 0, 0, 0.6);
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: none;
  border-radius: 50%;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.6);
}

.p-paginator .p-paginator-first {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-paginator .p-paginator-last {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 3rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}

.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: rgba(0, 0, 0, 0.6);
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: rgba(0, 0, 0, 0.6);
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: none;
  border-radius: 50%;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: rgba(25, 50, 90, 0.12);
  border-color: rgba(25, 50, 90, 0.12);
  color: #19325A;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.6);
}

.p-picklist .p-picklist-buttons {
  padding: 0.75rem;
}

.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}

.p-picklist .p-picklist-header {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e0e0e0;
  padding: 0.75rem;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-picklist .p-picklist-header .p-picklist-title {
  font-weight: 500;
}

.p-picklist .p-picklist-filter-container {
  padding: 0.75rem;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-bottom: 0 none;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 1.75rem;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}

.p-picklist .p-picklist-list {
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.75rem 0.75rem;
  margin: 0;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  transition: none;
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #19325A;
  background: rgba(25, 50, 90, 0.12);
}

.p-picklist .p-picklist-list .p-picklist-empty-message {
  padding: 0.75rem 0.75rem;
  color: rgba(0, 0, 0, 0.87);
}

.p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.p-picklist-item.cdk-drag-preview {
  padding: 0.75rem 0.75rem;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  margin: 0;
}

.p-timeline .p-timeline-event-marker {
  border: 0 none;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: #bdbdbd;
}

.p-timeline .p-timeline-event-connector {
  background-color: #bdbdbd;
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}

.p-tree {
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.75rem;
  border-radius: 4px;
}

.p-tree .p-tree-container .p-treenode {
  padding: 0.25rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 4px;
  transition: none;
  padding: 0.25rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: rgba(0, 0, 0, 0.6);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: rgba(0, 0, 0, 0.87);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: rgba(25, 50, 90, 0.12);
  color: #19325A;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #19325A;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: #19325A;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}

.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.75rem;
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}

.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}

.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}

.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: rgba(50, 65, 145, 0.12);
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.25rem;
  transition: none;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: rgba(25, 50, 90, 0.12);
  color: #19325A;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #19325A;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-treetable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-treetable .p-treetable-header {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 0.75rem 0.75rem;
  font-weight: 500;
}

.p-treetable .p-treetable-footer {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 0.75rem 0.75rem;
  font-weight: 500;
}

.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 0.75rem 0.75rem;
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  transition: none;
}

.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 0.75rem 0.75rem;
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
}

.p-treetable .p-sortable-column {
  outline-color: transparent;
}

.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 0.5rem;
}

.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #19325A;
  background: rgba(25, 50, 90, 0.12);
  margin-left: 0.5rem;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-treetable .p-sortable-column.p-highlight {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}

.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: rgba(0, 0, 0, 0.87);
}

.p-treetable .p-treetable-tbody > tr {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  transition: none;
  outline-color: transparent;
}

.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 0.75rem 0.75rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
  margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: rgba(0, 0, 0, 0.87);
}

.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: rgba(25, 50, 90, 0.12);
  color: #19325A;
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #19325A;
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #19325A;
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: rgba(0, 0, 0, 0.87);
}

.p-treetable .p-column-resizer-helper {
  background: #19325A;
}

.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #ffffff;
}

.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}

.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}

.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.65625rem 0.65625rem;
}

.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.375rem 0.375rem;
}

.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.375rem 0.375rem;
}

.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.375rem 0.375rem;
}

.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.375rem 0.375rem;
}

.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 0.9375rem 0.9375rem;
}

.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 0.9375rem 0.9375rem;
}

.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 0.9375rem 0.9375rem;
}

.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 0.9375rem 0.9375rem;
}

.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 0.9375rem 0.9375rem;
}

.p-virtualscroller .p-virtualscroller-header {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 0.75rem 0.75rem;
  font-weight: 500;
}

.p-virtualscroller .p-virtualscroller-content {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  padding: 0.75rem;
}

.p-virtualscroller .p-virtualscroller-footer {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 0.75rem 0.75rem;
  font-weight: 500;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.5rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  transition: none;
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #f6f6f6;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #ffffff;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: transparent;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}

.p-accordion .p-accordion-content {
  padding: 0.75rem 1.25rem;
  border: 0 none;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}

.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}

.p-accordion p-accordiontab .p-accordion-content {
  border-radius: 0;
}

.p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}

.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0 none;
}

.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-accordion p-accordiontab:last-child .p-accordion-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-card {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.p-card .p-card-body {
  padding: 0.75rem;
}

.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: rgba(0, 0, 0, 0.6);
}

.p-card .p-card-footer {
  padding: 0.75rem 0 0 0;
}

.p-divider .p-divider-content {
  background-color: #ffffff;
}

.p-divider.p-divider-horizontal {
  margin: 0.5rem 0;
  padding: 0 1.25rem;
}

.p-divider.p-divider-horizontal:before {
  border-top: 1px rgba(0, 0, 0, 0.12);
}

.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}

.p-divider.p-divider-vertical {
  margin: 0 0.5rem;
  padding: 1.25rem 0;
}

.p-divider.p-divider-vertical:before {
  border-left: 1px rgba(0, 0, 0, 0.12);
}

.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}

.p-fieldset {
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
}

.p-fieldset .p-fieldset-legend {
  padding: 0.75rem;
  border: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  font-weight: 500;
  border-radius: 4px;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  transition: none;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: rgba(0, 0, 0, 0.04);
  border-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.p-fieldset .p-fieldset-content {
  padding: 0.75rem;
}

.p-panel .p-panel-header {
  border: 1px solid #e0e0e0;
  padding: 0.75rem;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-panel .p-panel-header .p-panel-title {
  font-weight: 500;
}

.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem 1rem;
}

.p-panel .p-panel-content {
  padding: 0.75rem;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 0 none;
}

.p-panel .p-panel-footer {
  padding: 0.75rem 0.75rem;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-top: 0 none;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: rgba(0, 0, 0, 0.12);
  border: 0 none;
}

.p-splitter {
  border: 1px solid #e0e0e0;
  background: #ffffff;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
}

.p-splitter .p-splitter-gutter {
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  background: rgba(0, 0, 0, 0.04);
}

.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: rgba(0, 0, 0, 0.12);
}

.p-splitter .p-splitter-gutter-resizing {
  background: rgba(0, 0, 0, 0.12);
}

.p-tabview .p-tabview-nav {
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.12);
  border-width: 0 0 1px 0;
}

.p-tabview .p-tabview-nav li {
  margin-right: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: none;
  border-width: 0 0 0 0;
  border-color: transparent transparent transparent transparent;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.6);
  padding: 0.75rem 1.25rem;
  font-weight: 500;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: none;
  margin: 0 0 0 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: rgba(25, 50, 90, 0.04);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.6);
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: transparent;
  color: #19325A;
}

.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}

.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}

.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}

.p-tabview .p-tabview-panels {
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-toolbar {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 0.75rem;
  border-radius: 4px;
}

.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

.p-confirm-popup {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-confirm-popup .p-confirm-popup-content {
  padding: 0.75rem;
}

.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0.75rem 0.75rem;
}

.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}

.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}

.p-confirm-popup:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-confirm-popup:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: #ffffff;
}

.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: #ffffff;
}

.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}

.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}

.p-dialog {
  border-radius: 4px;
  box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.25);
  border: 0 none;
}

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 1.25rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 500;
  font-size: 1.25rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 1.25rem 1.25rem 1.25rem;
}

.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.75rem 1.25rem;
  text-align: right;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(25, 50, 90, 0.6) !important;
}

.p-overlaypanel {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0.75rem;
}

.p-overlaypanel .p-overlaypanel-close {
  background: #19325A;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}

.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: rgba(25, 50, 90, 0.92);
  color: #ffffff;
}

.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}

.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #ffffff;
}

.p-sidebar {
  background-color: #19325a;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.sidebar-list-item span {
  margin-left: 8px !important;
}

.p-sidebar .p-sidebar-header {
  padding: 0.75rem;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 2px;
  color: white;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding-top: 0;
}

.p-sidebar .p-sidebar-content {
  padding: 0.75rem;
}

.p-sidebar-mask.p-component-overlay {
  background: rgba(0, 0, 0, 0.32);
}

.p-tooltip .p-tooltip-text {
  background: rgba(97, 97, 97, 0.9);
  color: #ffffff;
  padding: 0.5rem;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: rgba(97, 97, 97, 0.9);
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: rgba(97, 97, 97, 0.9);
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: rgba(97, 97, 97, 0.9);
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: rgba(97, 97, 97, 0.9);
}

.p-breadcrumb {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 0.75rem;
}

.p-breadcrumb ul li .p-menuitem-link {
  transition: none;
  border-radius: 4px;
}

.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: rgba(0, 0, 0, 0.6);
}

.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-contextmenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 12.5rem;
}

.p-contextmenu .p-menuitem-link {
  padding: 0.75rem 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-contextmenu .p-submenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-contextmenu .p-menuitem {
  margin: 0;
}

.p-contextmenu .p-menuitem:last-child {
  margin: 0;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: rgba(0, 0, 0, 0.04);
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-contextmenu .p-menu-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
}

.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-megamenu {
  padding: 0.75rem;
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  transition: none;
  user-select: none;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 0.5rem;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-megamenu .p-menuitem-link {
  padding: 0.75rem 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}

.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-megamenu .p-megamenu-panel {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  background: #ffffff;
  font-weight: 400;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-megamenu .p-megamenu-submenu {
  padding: 0.5rem 0;
  width: 12.5rem;
}

.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
}

.p-megamenu .p-megamenu-submenu .p-menuitem {
  margin: 0;
}

.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
  margin: 0;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: rgba(0, 0, 0, 0.04);
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.5rem 0;
}

.p-megamenu.p-megamenu-vertical .p-menuitem {
  margin: 0;
}

.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
  margin: 0;
}

.p-menu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: gray !important;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 12.5rem;
}

.p-menu .p-menuitem-link {
  padding: 0.75rem 0.75rem;
  color: gray !important;
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-menu .p-menuitem-link .p-menuitem-text {
  color: gray !important;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  color: var(--c-font-dark);
  margin-right: 0.5rem;
}

.p-menu .p-menuitem-link .p-submenu-icon {
  color: white !important;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--c-nordsol100);
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: var(--c-nordsol);
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--c-nordsol);
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--c-nordsol);
}

.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-menu.p-menu-overlay {
  background: var(--c-background-light);
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  background: #ffffff;
  font-weight: 400;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.p-menu .p-menu-separator {
  border-top: 1px solid var(--c-border-neutral);
  margin: 0.5rem 0;
}

.p-menu .p-menuitem {
  margin: 0;
}

.p-menu .p-menuitem:last-child {
  margin: 0;
}

.p-menubar {
  padding: 0.75rem;
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.p-menubar .p-menuitem-link {
  padding: 0.75rem 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-menubar .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}

.p-menubar .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  transition: none;
  user-select: none;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 0.5rem;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-menubar .p-submenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 12.5rem;
}

.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
}

.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}

.p-menubar .p-submenu-list .p-menuitem {
  margin: 0;
}

.p-menubar .p-submenu-list .p-menuitem:last-child {
  margin: 0;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: rgba(0, 0, 0, 0.04);
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-menubar .p-menubar-button:hover {
    color: rgba(0, 0, 0, 0.6);
    background: rgba(0, 0, 0, 0.04);
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.5rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin: 0.5rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 0.75rem;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0;
    transition: none;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: rgba(0, 0, 0, 0.87);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.6);
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: rgba(0, 0, 0, 0.6);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(0, 0, 0, 0.04);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: rgba(0, 0, 0, 0.87);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.6);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: rgba(0, 0, 0, 0.6);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 1.25rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  border-radius: 4px;
  transition: none;
}

.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  border-color: transparent;
  color: #336CCC;
  font-weight: 700;
}

.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: #19325A;
  border-color: transparent;
  color: #fff;
  border-radius: 10px 10px 0 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: transparent;
}

.p-panelmenu .p-panelmenu-content {
  padding: 0.5rem 0;
  border: 0 none;
  background-color: #D6E1F4;
  color: #19325A !important;
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  margin: 0 5px;
  padding: 1rem 0 1rem 2rem;
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(51, 108, 204, 0.3);
  border-radius: 6px;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #fff;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #fff;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a,
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0 none;
}

.p-slidemenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 12.5rem;
}

.p-slidemenu .p-menuitem-link {
  padding: 0.75rem 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-slidemenu.p-slidemenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-slidemenu .p-slidemenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: rgba(0, 0, 0, 0.04);
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon,
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
}

.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}

.p-slidemenu .p-slidemenu-backward {
  padding: 0.75rem 0.75rem;
  color: rgba(0, 0, 0, 0.87);
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: none;
  border-radius: 4px;
  background: transparent;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid transparent;
  background: transparent;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: rgba(0, 0, 0, 0.87);
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: rgba(25, 50, 90, 0.12);
  color: #19325A;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
  background: #FEFEFE;
  border: solid rgba(0, 0, 0, 0.12);
  border-width: 0 0 1px 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: none;
  border-width: 0 0 0 0;
  border-color: transparent transparent transparent transparent;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.6);
  padding: 0.75rem 1.25rem;
  font-weight: 500;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: none;
  margin: 0 0 0 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: rgba(25, 50, 90, 0.04);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.6);
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: transparent;
  color: #19325A;
}

.p-tieredmenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 12.5rem;
}

.p-tieredmenu .p-menuitem-link {
  padding: 0.75rem 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-tieredmenu.p-tieredmenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-tieredmenu .p-submenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-tieredmenu .p-menuitem {
  margin: 0;
}

.p-tieredmenu .p-menuitem:last-child {
  margin: 0;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: rgba(0, 0, 0, 0.04);
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-tieredmenu .p-menu-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
}

.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-inline-message {
  padding: 0.75rem 0.75rem;
  margin: 0;
  border-radius: 4px;
}

.p-inline-message.p-inline-message-info {
  background: #B3E5FC;
  border: solid transparent;
  border-width: 1px;
  color: #01579B;
}

.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #01579B;
}

.p-inline-message.p-inline-message-success {
  background: #C8E6C9;
  border: solid transparent;
  border-width: 1px;
  color: #1B5E20;
}

.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #1B5E20;
}

.p-inline-message.p-inline-message-warn {
  background: #FFECB3;
  border: solid transparent;
  border-width: 1px;
  color: #7f6003;
}

.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #7f6003;
}

.p-inline-message.p-inline-message-error {
  background: #FFCDD2;
  border: solid transparent;
  border-width: 1px;
  color: #B71C1C;
}

.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #B71C1C;
}

.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}

.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 0.75rem 0;
  border-radius: 4px;
}

.p-message .p-message-wrapper {
  padding: 1rem 1.25rem;
}

.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}

.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-message.p-message-info {
  background: #B3E5FC;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #01579B;
}

.p-message.p-message-info .p-message-icon {
  color: #01579B;
}

.p-message.p-message-info .p-message-close {
  color: #01579B;
}

.p-message.p-message-success {
  background: #C8E6C9;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #1B5E20;
}

.p-message.p-message-success .p-message-icon {
  color: #1B5E20;
}

.p-message.p-message-success .p-message-close {
  color: #1B5E20;
}

.p-message.p-message-warn {
  background: #FFECB3;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #7f6003;
}

.p-message.p-message-warn .p-message-icon {
  color: #7f6003;
}

.p-message.p-message-warn .p-message-close {
  color: #7f6003;
}

.p-message.p-message-error {
  background: #FFCDD2;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #B71C1C;
}

.p-message.p-message-error .p-message-icon {
  color: #B71C1C;
}

.p-message.p-message-error .p-message-close {
  color: #B71C1C;
}

.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}

.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.p-message .p-message-summary {
  font-weight: 700;
}

.p-message .p-message-detail {
  margin-left: 0.5rem;
}

.p-toast {
  opacity: 0.9;
}

.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 1.25rem;
  border-width: 0 0 0 0;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}

.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}

.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-toast .p-toast-message.p-toast-message-info {
  background: #B3E5FC;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #01579B;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #01579B;
}

.p-toast .p-toast-message.p-toast-message-success {
  background: #C8E6C9;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #1B5E20;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #1B5E20;
}

.p-toast .p-toast-message.p-toast-message-warn {
  background: #FFECB3;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #7f6003;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #7f6003;
}

.p-toast .p-toast-message.p-toast-message-error {
  background: #FFCDD2;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #B71C1C;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #B71C1C;
}

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: rgba(255, 255, 255, 0.87);
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}

.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}

.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.87);
}

.p-galleria .p-galleria-item-nav {
  background: var(--c-background-light);
  opacity: 0.8;
  color: #336CCC;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  margin: 0 0.5rem;
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}

.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(25, 50, 90, 0.3);
  color: #19325A;
}

.p-galleria .p-galleria-caption {
  background: var(--c-nordsol);
  opacity: 0.8;
  color: var(--c-font-light);
  padding: 0.3rem 1rem;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.p-galleria .p-galleria-indicators {
  padding: 1rem;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: #dcdcdc;
  width: 1.25rem;
  height: 1.25rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #ececec;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: rgba(25, 50, 90, 0.12);
  color: #19325A;
}

.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
.p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}

.p-galleria.p-galleria-indicators-left .p-galleria-indicator,
.p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: rgba(255, 255, 255, 0.4);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(255, 255, 255, 0.6);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: rgba(25, 50, 90, 0.12);
  color: #19325A;
}

.p-galleria .p-galleria-thumbnail-container {
  background: transparent;
  padding: 1rem 0.25rem;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.87);
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.87);
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-galleria-mask.p-component-overlay {
  z-index: 999999 !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.p-avatar {
  background-color: rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px;
}

.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}

.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}

.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}

.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}

.p-avatar-group .p-avatar {
  border: 2px solid #ffffff;
}

.p-badge {
  background: #19325A;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}

.p-badge.p-badge-secondary {
  background-color: #336ccc;
  color: #ffffff;
}

.p-badge.p-badge-success {
  background-color: #689F38;
  color: #ffffff;
}

.p-badge.p-badge-info {
  background-color: #2196F3;
  color: #ffffff;
}

.p-badge.p-badge-warning {
  background-color: #FBC02D;
  color: #212529;
}

.p-badge.p-badge-danger {
  background-color: #D32F2F;
  color: #ffffff;
}

.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}

.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.p-blockui.p-component-overlay {
  background: rgba(0, 0, 0, 0.32);
}

.p-chip {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 16px;
  padding: 0 0.75rem;
}

.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}

.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}

.p-chip .pi-chip-remove-icon {
  margin-left: 0.5rem;
}

.p-chip img {
  width: 2.25rem;
  height: 2.25rem;
  margin-left: -0.75rem;
  margin-right: 0.5rem;
}

.p-chip .pi-chip-remove-icon {
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-chip .pi-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-inplace .p-inplace-display {
  padding: 1rem 1rem;
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-progressbar {
  border: 0 none;
  height: 4px;
  background: rgba(25, 50, 90, 0.32);
  border-radius: 4px;
}

.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #19325A;
}

.p-progressbar .p-progressbar-label {
  color: rgba(0, 0, 0, 0.87);
  line-height: 4px;
}

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-scrolltop.p-link {
  background: #336ccc;
}

.p-scrolltop.p-link:hover {
  background: rgba(51, 108, 204, 0.92);
}

.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: #ffffff;
}

.p-skeleton {
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-tag {
  background: #19325A;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
}

.p-tag.p-tag-success {
  background-color: #689F38;
  color: #ffffff;
}

.p-tag.p-tag-info {
  background-color: #2196F3;
  color: #ffffff;
}

.p-tag.p-tag-warning {
  background-color: #FBC02D;
  color: #212529;
}

.p-tag.p-tag-danger {
  background-color: #D32F2F;
  color: #ffffff;
}

.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.75rem;
}

.p-terminal {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e0e0e0;
  padding: 0.75rem;
}

.p-terminal .p-terminal-input {
  font-size: 1rem;
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
}

.p-accordion .p-accordion-tab {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
  position: relative;
  transition: margin-bottom 225ms;
  margin-bottom: 1rem !important;
  border-radius: 4px !important;
}

.p-accordion .p-accordion-tab .p-accordion-toggle-icon {
  order: 1;
  margin-left: auto;
  transition: transform 0.2s;
}

.p-accordion .p-accordion-tab:not(.p-accordion-tab-active) .p-accordion-header-link:focus {
  background: #f6f6f6;
}

.p-accordion .p-accordion-tab:not(.p-accordion-tab-active) .p-accordion-toggle-icon {
  transform: rotate(-270deg);
}

.p-accordion .p-accordion-tab.p-accordion-tab-active .p-accordion-toggle-icon {
  transform: rotate(-180deg);
}

.p-accordion .p-accordion-tab .p-accordion-header.p-disabled {
  opacity: 1;
}

.p-accordion .p-accordion-tab .p-accordion-header.p-disabled .p-accordion-header-link > * {
  opacity: 0.38;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A;
}

.p-input-filled .p-autocomplete .p-autocomplete-multiple-container {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #f5f5f5 no-repeat;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}

.p-input-filled .p-autocomplete .p-autocomplete-multiple-container .p-inputtext {
  background-image: none;
  background-color: transparent;
}

.p-input-filled .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  background-color: #ececec;
  border-color: transparent;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
}

.p-input-filled .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus,
.p-input-filled .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-color: #dcdcdc;
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}

.p-input-filled .p-autocomplete .p-autocomplete-multiple-container .p-inputtext:enabled:hover,
.p-input-filled .p-autocomplete .p-autocomplete-multiple-container .p-inputtext:enabled:focus {
  background-image: none;
  background: transparent;
}

.p-float-label .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.25rem 1rem;
}

.p-input-filled .p-float-label .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding-top: 0;
  padding-bottom: 0;
}

.p-input-filled .p-float-label .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  font-size: 75%;
}

.p-input-filled .p-float-label .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0;
}

.p-input-filled p-autocomplete.ng-dirty.ng-invalid .p-autocomplete > .p-inputtext {
  border-color: transparent;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-autocomplete.ng-dirty.ng-invalid .p-autocomplete > .p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: transparent;
}

.p-input-filled p-autocomplete.ng-dirty.ng-invalid .p-autocomplete .p-autocomplete-multiple-container {
  border-color: transparent;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-autocomplete.ng-dirty.ng-invalid .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-autocomplete.ng-dirty.ng-invalid .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus,
.p-input-filled p-autocomplete.ng-dirty.ng-invalid .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

p-autocomplete.ng-dirty.ng-invalid .p-autocomplete > .p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger);
}

p-autocomplete.ng-dirty.ng-invalid .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger);
}

.p-button.p-button-icon-only {
  min-width: auto;
}

.p-button:enabled:focus {
  background: rgba(25, 50, 90, 0.76);
}

.p-button:enabled:active {
  background: rgba(25, 50, 90, 0.68);
}

.p-button .p-ink {
  background-color: rgba(255, 255, 255, 0.32);
}

.p-button.p-button-text:enabled:focus,
.p-button.p-button-outlined:enabled:focus {
  background: rgba(25, 50, 90, 0.12);
}

.p-button.p-button-text:enabled:active,
.p-button.p-button-outlined:enabled:active {
  background: rgba(25, 50, 90, 0.16);
}

.p-button.p-button-text .p-ink,
.p-button.p-button-outlined .p-ink {
  background-color: rgba(25, 50, 90, 0.16);
}

.p-button:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.38) !important;
  opacity: 1;
}

.p-button:disabled.p-button-text {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.38) !important;
}

.p-button:disabled.p-button-outlined {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.38) !important;
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.p-button.p-button-raised:enabled:focus {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-button.p-button-secondary:enabled:focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  background: rgba(51, 108, 204, 0.76);
}

.p-button.p-button-secondary:enabled:active,
.p-buttonset.p-button-secondary > .p-button:enabled:active,
.p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: rgba(51, 108, 204, 0.68);
}

.p-button.p-button-secondary.p-button-text:enabled:focus,
.p-button.p-button-secondary.p-button-outlined:enabled:focus,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:focus,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:focus {
  background: rgba(51, 108, 204, 0.12);
}

.p-button.p-button-secondary.p-button-text:enabled:active,
.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(51, 108, 204, 0.16);
}

.p-button.p-button-secondary.p-button-text .p-ink,
.p-button.p-button-secondary.p-button-outlined .p-ink,
.p-buttonset.p-button-secondary > .p-button.p-button-text .p-ink,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined .p-ink,
.p-splitbutton.p-button-secondary > .p-button.p-button-text .p-ink,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined .p-ink {
  background-color: rgba(51, 108, 204, 0.16);
}

.p-button.p-button-info:enabled:focus,
.p-buttonset.p-button-info > .p-button:enabled:focus,
.p-splitbutton.p-button-info > .p-button:enabled:focus {
  background: rgba(33, 150, 243, 0.76);
}

.p-button.p-button-info:enabled:active,
.p-buttonset.p-button-info > .p-button:enabled:active,
.p-splitbutton.p-button-info > .p-button:enabled:active {
  background: rgba(33, 150, 243, 0.68);
}

.p-button.p-button-info.p-button-text:enabled:focus,
.p-button.p-button-info.p-button-outlined:enabled:focus,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:focus,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:focus,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:focus,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:focus {
  background: rgba(33, 150, 243, 0.12);
}

.p-button.p-button-info.p-button-text:enabled:active,
.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(33, 150, 243, 0.16);
}

.p-button.p-button-info.p-button-text .p-ink,
.p-button.p-button-info.p-button-outlined .p-ink,
.p-buttonset.p-button-info > .p-button.p-button-text .p-ink,
.p-buttonset.p-button-info > .p-button.p-button-outlined .p-ink,
.p-splitbutton.p-button-info > .p-button.p-button-text .p-ink,
.p-splitbutton.p-button-info > .p-button.p-button-outlined .p-ink {
  background-color: rgba(33, 150, 243, 0.16);
}

.p-button.p-button-success:enabled:focus,
.p-buttonset.p-button-success > .p-button:enabled:focus,
.p-splitbutton.p-button-success > .p-button:enabled:focus {
  background: rgba(104, 159, 56, 0.76);
}

.p-button.p-button-success:enabled:active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success > .p-button:enabled:active {
  background: rgba(104, 159, 56, 0.68);
}

.p-button.p-button-success.p-button-text:enabled:focus,
.p-button.p-button-success.p-button-outlined:enabled:focus,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:focus,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:focus,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:focus,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:focus {
  background: rgba(104, 159, 56, 0.12);
}

.p-button.p-button-success.p-button-text:enabled:active,
.p-button.p-button-success.p-button-outlined:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(104, 159, 56, 0.16);
}

.p-button.p-button-success.p-button-text .p-ink,
.p-button.p-button-success.p-button-outlined .p-ink,
.p-buttonset.p-button-success > .p-button.p-button-text .p-ink,
.p-buttonset.p-button-success > .p-button.p-button-outlined .p-ink,
.p-splitbutton.p-button-success > .p-button.p-button-text .p-ink,
.p-splitbutton.p-button-success > .p-button.p-button-outlined .p-ink {
  background-color: rgba(104, 159, 56, 0.16);
}

.p-button.p-button-warning:enabled:focus,
.p-buttonset.p-button-warning > .p-button:enabled:focus,
.p-splitbutton.p-button-warning > .p-button:enabled:focus {
  background: rgba(251, 192, 45, 0.76);
}

.p-button.p-button-warning:enabled:active,
.p-buttonset.p-button-warning > .p-button:enabled:active,
.p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: rgba(251, 192, 45, 0.68);
}

.p-button.p-button-warning.p-button-text:enabled:focus,
.p-button.p-button-warning.p-button-outlined:enabled:focus,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:focus,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:focus,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:focus,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:focus {
  background: rgba(251, 192, 45, 0.12);
}

.p-button.p-button-warning.p-button-text:enabled:active,
.p-button.p-button-warning.p-button-outlined:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(251, 192, 45, 0.16);
}

.p-button.p-button-warning.p-button-text .p-ink,
.p-button.p-button-warning.p-button-outlined .p-ink,
.p-buttonset.p-button-warning > .p-button.p-button-text .p-ink,
.p-buttonset.p-button-warning > .p-button.p-button-outlined .p-ink,
.p-splitbutton.p-button-warning > .p-button.p-button-text .p-ink,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined .p-ink {
  background-color: rgba(251, 192, 45, 0.16);
}

.p-button.p-button-help:enabled:focus,
.p-buttonset.p-button-help > .p-button:enabled:focus,
.p-splitbutton.p-button-help > .p-button:enabled:focus {
  background: rgba(156, 39, 176, 0.76);
}

.p-button.p-button-help:enabled:active,
.p-buttonset.p-button-help > .p-button:enabled:active,
.p-splitbutton.p-button-help > .p-button:enabled:active {
  background: rgba(156, 39, 176, 0.68);
}

.p-button.p-button-help.p-button-text:enabled:focus,
.p-button.p-button-help.p-button-outlined:enabled:focus,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:focus,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:focus,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:focus,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:focus {
  background: rgba(156, 39, 176, 0.12);
}

.p-button.p-button-help.p-button-text:enabled:active,
.p-button.p-button-help.p-button-outlined:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(156, 39, 176, 0.16);
}

.p-button.p-button-help.p-button-text .p-ink,
.p-button.p-button-help.p-button-outlined .p-ink,
.p-buttonset.p-button-help > .p-button.p-button-text .p-ink,
.p-buttonset.p-button-help > .p-button.p-button-outlined .p-ink,
.p-splitbutton.p-button-help > .p-button.p-button-text .p-ink,
.p-splitbutton.p-button-help > .p-button.p-button-outlined .p-ink {
  background-color: rgba(156, 39, 176, 0.16);
}

.p-button.p-button-danger:enabled:focus,
.p-buttonset.p-button-danger > .p-button:enabled:focus,
.p-splitbutton.p-button-danger > .p-button:enabled:focus {
  background: rgba(211, 47, 47, 0.76);
}

.p-button.p-button-danger:enabled:active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: rgba(211, 47, 47, 0.68);
}

.p-button.p-button-danger.p-button-text:enabled:focus,
.p-button.p-button-danger.p-button-outlined:enabled:focus,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:focus,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:focus,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:focus,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:focus {
  background: rgba(211, 47, 47, 0.12);
}

.p-button.p-button-danger.p-button-text:enabled:active,
.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(211, 47, 47, 0.16);
}

.p-button.p-button-danger.p-button-text .p-ink,
.p-button.p-button-danger.p-button-outlined .p-ink,
.p-buttonset.p-button-danger > .p-button.p-button-text .p-ink,
.p-buttonset.p-button-danger > .p-button.p-button-outlined .p-ink,
.p-splitbutton.p-button-danger > .p-button.p-button-text .p-ink,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined .p-ink {
  background-color: rgba(211, 47, 47, 0.16);
}

.p-calendar-w-btn {
  border: 1px solid rgba(0, 0, 0, 0.38);
  background: #ffffff;
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.p-calendar-w-btn .p-inputtext {
  background-image: none;
  background-color: transparent;
  border: 0 none;
}

.p-calendar-w-btn .p-inputtext:enabled:focus {
  box-shadow: none;
}

.p-calendar-w-btn .p-datepicker-trigger.p-button {
  background-color: transparent;
  border: 0 none;
}

.p-calendar-w-btn .p-datepicker-trigger.p-button span {
  color: rgba(0, 0, 0, 0.6);
}

.p-calendar-w-btn .p-datepicker-trigger.p-button:enabled:hover {
  background: rgba(0, 0, 0, 0.04);
}

.p-calendar-w-btn .p-datepicker-trigger.p-button:enabled:active,
.p-calendar-w-btn .p-datepicker-trigger.p-button:focus {
  background: rgba(0, 0, 0, 0.12);
}

.p-calendar-w-btn .p-datepicker-trigger.p-button:disabled {
  background-color: transparent !important;
}

.p-calendar-w-btn:not(.p-calendar-disabled):hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.p-calendar-w-btn:not(.p-calendar-disabled).p-focus {
  border-color: #19325A;
  box-shadow: inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A;
}

.p-calendar-w-btn.p-calendar-disabled {
  opacity: 0.38;
}

.p-datepicker .p-datepicker-header {
  border-bottom: 0 none;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  margin: 0 auto 0 0;
  order: 1;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev {
  order: 2;
}

.p-datepicker .p-datepicker-header .p-datepicker-next {
  order: 3;
}

.p-datepicker table th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.38);
  font-weight: 400;
  font-size: 0.875rem;
}

.p-datepicker table td.p-datepicker-today > span {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.38);
}

.p-datepicker table td.p-datepicker-today.p-highlight {
  box-shadow: 0 0 0 1px rgba(25, 50, 90, 0.12);
}

p-calendar.ng-dirty.ng-invalid .p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger);
}

p-calendar.ng-dirty.ng-invalid .p-calendar-w-btn {
  border-color: var(--c-danger);
}

p-calendar.ng-dirty.ng-invalid .p-calendar-w-btn .p-inputtext:enabled:focus {
  box-shadow: none;
}

p-calendar.ng-dirty.ng-invalid .p-calendar-w-btn:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger);
}

.p-input-filled .p-calendar-w-btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #fff no-repeat;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}

.p-input-filled .p-calendar-w-btn .p-inputtext {
  background-image: none;
  background-color: transparent;
}

.p-input-filled .p-calendar-w-btn:not(.p-calendar-disabled):not(.p-disabled):hover {
  background-color: #fff;
  border-color: transparent;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
}

.p-input-filled .p-calendar-w-btn:not(.p-calendar-disabled):not(.p-disabled).p-focus,
.p-input-filled .p-calendar-w-btn:not(.p-calendar-disabled):not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-color: #dcdcdc;
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}

.p-input-filled .p-calendar-w-btn:not(.p-calendar-disabled) .p-inputtext:enabled:hover,
.p-input-filled .p-calendar-w-btn:not(.p-calendar-disabled) .p-inputtext:enabled:focus {
  background-image: none;
  background: transparent;
}

.p-input-filled .p-calendar-w-btn .p-inputtext {
  border: 0 none;
}

.p-input-filled p-calendar.ng-dirty.ng-invalid .p-inputtext {
  border-color: transparent;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-calendar.ng-dirty.ng-invalid .p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: transparent;
}

.p-input-filled p-calendar.ng-dirty.ng-invalid .p-calendar-w-btn {
  border-color: transparent;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-calendar.ng-dirty.ng-invalid .p-calendar-w-btn:not(.p-disabled):hover {
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-calendar.ng-dirty.ng-invalid .p-calendar-w-btn:not(.p-disabled).p-focus,
.p-input-filled p-calendar.ng-dirty.ng-invalid .p-calendar-w-btn:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-calendar.ng-dirty.ng-invalid .p-calendar-w-btn .p-inputtext {
  border: 0 none;
  background-image: none;
}

.p-cascadeselect .p-inputtext,
.p-cascadeselect .p-cascadeselect-trigger {
  background-image: none;
  background: transparent;
}

.p-cascadeselect .p-inputtext {
  border: 0 none;
}

.p-cascadeselect:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A;
}

.p-cascadeselect-item .p-ink {
  background-color: rgba(25, 50, 90, 0.16);
}

.p-input-filled .p-cascadeselect {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #f5f5f5 no-repeat;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}

.p-input-filled .p-cascadeselect .p-inputtext {
  background-image: none;
  background-color: transparent;
}

.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: #ececec;
  border-color: transparent;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
}

.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus,
.p-input-filled .p-cascadeselect:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-color: #dcdcdc;
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}

.p-input-filled .p-cascadeselect .p-inputtext:enabled:hover,
.p-input-filled .p-cascadeselect .p-inputtext:enabled:focus {
  background-image: none;
  background: transparent;
}

.p-input-filled .p-cascadeselect .p-inputtext {
  border: 0 none;
}

.p-input-filled .p-cascadeselect .p-cascadeselect-label:hover {
  background-image: none;
  background: transparent;
}

.p-input-filled p-cascadeselect.ng-dirty.ng-invalid .p-cascadeselect {
  border-color: transparent;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-cascadeselect.ng-dirty.ng-invalid .p-cascadeselect:not(.p-disabled):hover {
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-cascadeselect.ng-dirty.ng-invalid .p-cascadeselect:not(.p-disabled).p-focus,
.p-input-filled p-cascadeselect.ng-dirty.ng-invalid .p-cascadeselect:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled .p-float-label .p-cascadeselect .p-cascadeselect-label {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.p-input-filled .p-float-label .p-inputwrapper-filled .p-cascadeselect .p-cascadeselect-label {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.p-input-filled .p-cascadeselect.p-invalid {
  border-color: transparent;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled .p-cascadeselect.p-invalid:not(.p-disabled):hover {
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled .p-cascadeselect.p-invalid:not(.p-disabled).p-focus,
.p-input-filled .p-cascadeselect.p-invalid:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

p-cascadeselect.ng-dirty.ng-invalid .p-cascadeselect:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger);
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #336ccc;
  color: #ffffff;
}

.p-checkbox {
  border-radius: 50%;
  transition: box-shadow 0.2s;
}

.p-checkbox .p-checkbox-box {
  border-color: #757575;
  border-radius: 2px;
  position: relative;
}

.p-checkbox .p-checkbox-box:not(.p-disabled):hover {
  border-color: #757575;
}

.p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
  border-color: #757575;
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled).p-focus {
  border-color: #19325A;
}

.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 1px;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 125ms 50ms linear forwards;
}

.p-checkbox:not(.p-checkbox-disabled):hover {
  box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.04);
}

.p-checkbox:not(.p-checkbox-disabled).p-checkbox-focused {
  box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.12);
}

.p-checkbox.p-checkbox-checked:not(.p-checkbox-disabled):hover {
  box-shadow: 0 0 1px 10px rgba(25, 50, 90, 0.04);
}

.p-checkbox.p-checkbox-checked:not(.p-checkbox-disabled).p-checkbox-focused {
  box-shadow: 0 0 1px 10px rgba(25, 50, 90, 0.12);
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #ffffff;
}

.p-input-filled .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
  background-color: #ffffff;
}

@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #ffffff;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 4px;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 4px;
    height: 10px;
    border-color: #ffffff;
    transform: translate3d(0, -10px, 0) rotate(45deg);
  }
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A;
}

.p-input-filled .p-chips-multiple-container {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #f5f5f5 no-repeat;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}

.p-input-filled .p-chips-multiple-container .p-inputtext {
  background-image: none;
  background-color: transparent;
}

.p-input-filled .p-chips-multiple-container:not(.p-disabled):hover {
  background-color: #ececec;
  border-color: transparent;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
}

.p-input-filled .p-chips-multiple-container:not(.p-disabled).p-focus,
.p-input-filled .p-chips-multiple-container:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-color: #dcdcdc;
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}

.p-input-filled .p-chips-multiple-container .p-inputtext:enabled:hover,
.p-input-filled .p-chips-multiple-container .p-inputtext:enabled:focus {
  background-image: none;
  background: transparent;
}

.p-float-label .p-chips-multiple-container .p-chips-token {
  padding: 0.25rem 1rem;
}

.p-input-filled .p-float-label .p-chips .p-chips-multiple-container .p-chips-token {
  padding-top: 0;
  padding-bottom: 0;
}

.p-input-filled .p-float-label .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  font-size: 75%;
}

.p-input-filled .p-float-label .p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0;
}

.p-input-filled p-chips.ng-dirty.ng-invalid .p-chips .p-chips-multiple-container {
  border-color: transparent;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-chips.ng-dirty.ng-invalid .p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-chips.ng-dirty.ng-invalid .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus,
.p-input-filled p-chips.ng-dirty.ng-invalid .p-chips .p-chips-multiple-container:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

p-chips.ng-dirty.ng-invalid .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger);
}

.p-datatable .p-sortable-column {
  outline: 0 none;
}

.p-datatable .p-sortable-column:focus {
  background-color: rgba(0, 0, 0, 0.03);
}

.p-datatable .p-datatable-tbody > tr {
  outline: 0 none;
}

.p-datatable .p-datatable-tbody > tr:not(.p-highlight):focus {
  background-color: rgba(0, 0, 0, 0.03);
}

.p-dropdown .p-inputtext,
.p-dropdown .p-dropdown-trigger {
  background-image: none;
  background: transparent;
}

.p-dropdown .p-inputtext {
  border: 0 none;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A;
}

.p-dropdown-item .p-ink {
  background-color: rgba(25, 50, 90, 0.16);
}

.p-input-filled .p-dropdown {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: transparent no-repeat;
  background-size: 0 2px, 100% 1px;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}

.p-input-filled .p-dropdown .p-inputtext {
  background-image: none;
  background-color: transparent;
}

.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: transparent;
  border-color: transparent;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus,
.p-input-filled .p-dropdown:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-color: transparent;
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}

.p-input-filled .p-dropdown .p-inputtext:enabled:hover,
.p-input-filled .p-dropdown .p-inputtext:enabled:focus {
  background-image: none;
  background: transparent;
}

.p-input-filled .p-dropdown .p-inputtext {
  border: 0 none;
}

.p-input-filled p-dropdown.ng-dirty.ng-invalid .p-dropdown {
  border-color: transparent;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-dropdown.ng-dirty.ng-invalid .p-dropdown:not(.p-disabled):hover {
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-dropdown.ng-dirty.ng-invalid .p-dropdown:not(.p-disabled).p-focus,
.p-input-filled p-dropdown.ng-dirty.ng-invalid .p-dropdown:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

p-dropdown.ng-dirty.ng-invalid .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger);
}

.p-galleria .p-galleria-indicators {
  padding: 1rem;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #336ccc;
  color: #ffffff;
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: rgba(51, 108, 204, 0.68);
  color: #ffffff;
}

.p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A;
}

.p-inputtext:enabled:focus.ng-invalid.ng-dirty {
  box-shadow: inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger);
}

.p-input-filled .p-inputtext {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #f5f5f5 no-repeat;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}

.p-input-filled .p-inputtext:enabled:hover {
  background-color: #ececec;
  border-color: transparent;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
}

.p-input-filled .p-inputtext:enabled:focus {
  box-shadow: none;
  background-color: #dcdcdc;
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}

.p-input-filled .p-inputtext.ng-invalid.ng-dirty {
  border-color: transparent;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled .p-inputtext.ng-invalid.ng-dirty:enabled:focus {
  box-shadow: none;
  border-color: transparent;
}

.p-input-filled .p-inputgroup .p-inputgroup-addon {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: transparent;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, #fff, #fff);
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}

.p-input-filled .p-inputgroup .p-inputgroup-addon:last-child {
  border-right-color: transparent;
}

.p-input-filled .p-inputgroup-addon:first-child,
.p-input-filled .p-inputgroup button:first-child,
.p-input-filled .p-inputgroup input:first-child {
  border-bottom-left-radius: 0;
}

.p-input-filled .p-inputgroup .p-float-label:first-child input {
  border-bottom-left-radius: 0;
}

.p-input-filled .p-inputgroup-addon:last-child,
.p-input-filled .p-inputgroup button:last-child,
.p-input-filled .p-inputgroup input:last-child {
  border-bottom-right-radius: 0;
}

.p-input-filled .p-inputgroup .p-float-label:last-child input {
  border-bottom-right-radius: 0;
}

p-inputmask.ng-dirty.ng-invalid .p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger);
}

.p-input-filled p-inputmask.ng-dirty.ng-invalid .p-inputtext {
  border-color: transparent;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-inputmask.ng-dirty.ng-invalid .p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: transparent;
}

p-inputnumber.ng-dirty.ng-invalid .p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger);
}

.p-input-filled p-inputnumber.ng-dirty.ng-invalid .p-inputtext {
  border-color: transparent;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-inputnumber.ng-dirty.ng-invalid .p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: transparent;
}

.p-inputswitch .p-inputswitch-slider:before {
  transition-property: box-shadow transform;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider:before {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0 0 1px 10px rgba(0, 0, 0, 0.04);
}

.p-inputswitch.p-inputswitch-focus .p-inputswitch-slider:before,
.p-inputswitch.p-inputswitch-focus:not(.p-disabled):hover .p-inputswitch-slider:before {
  box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.12), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider:before {
  box-shadow: 0 0 1px 10px rgba(25, 50, 90, 0.04), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider:before,
.p-inputswitch.p-inputswitch-checked.p-inputswitch-focus:not(.p-disabled):hover .p-inputswitch-slider:before {
  box-shadow: 0 0 1px 10px rgba(25, 50, 90, 0.12), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.p-fieldset .p-fieldset-legend {
  border: 0 none;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: -0.5rem !important;
  background-color: transparent;
  padding: 2px 4px;
  margin-left: -4px;
  margin-top: 0;
}

textarea {
  border-radius: 1rem;
}

.p-float-label textarea ~ label {
  margin-top: 0;
}

.p-float-label input:focus ~ label,
.p-float-label .p-inputwrapper-focus ~ label {
  color: #19325A;
}

.p-input-filled .p-float-label .p-inputtext {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.p-input-filled .p-float-label input:focus ~ label,
.p-input-filled .p-float-label input.p-filled ~ label,
.p-input-filled .p-float-label textarea:focus ~ label,
.p-input-filled .p-float-label textarea.p-filled ~ label,
.p-input-filled .p-float-label .p-inputwrapper-focus ~ label,
.p-input-filled .p-float-label .p-inputwrapper-filled ~ label {
  top: 0.5rem !important;
  margin-top: 0;
  background: transparent;
}

.p-listbox-item .p-ink {
  background-color: rgba(25, 50, 90, 0.16);
}

.p-multiselect .p-multiselect-label,
.p-multiselect .p-multiselect-trigger {
  background-image: none;
  background: transparent;
}

.p-multiselect .p-multiselect-label {
  border: 0 none;
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A;
}

.p-multiselect-item .p-ink {
  background-color: rgba(25, 50, 90, 0.16);
}

.p-input-filled .p-multiselect {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: transparent no-repeat;
  background-size: 0 2px, 100% 1px;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}

.p-input-filled .p-multiselect .p-inputtext {
  background-image: none;
  background-color: transparent;
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  border-color: transparent;
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus,
.p-input-filled .p-multiselect:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-color: #dcdcdc;
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}

.p-input-filled .p-multiselect .p-inputtext:enabled:hover,
.p-input-filled .p-multiselect .p-inputtext:enabled:focus {
  background-image: none;
  background: transparent;
}

.p-input-filled .p-multiselect .p-multiselect-label:hover {
  background-image: none;
  background: transparent;
}

.p-float-label .p-multiselect-label .p-multiselect-token {
  padding: 0.25rem 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.p-input-filled .p-float-label .p-multiselect .p-multiselect-label {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.p-input-filled .p-float-label .p-inputwrapper-filled .p-multiselect .p-multiselect-label {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.p-input-filled .p-float-label .p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.p-input-filled .p-float-label .p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  font-size: 75%;
}

.p-input-filled p-multiselect.ng-dirty.ng-invalid .p-multiselect {
  border-color: transparent;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-multiselect.ng-dirty.ng-invalid .p-multiselect:not(.p-disabled):hover {
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled p-multiselect.ng-dirty.ng-invalid .p-multiselect:not(.p-disabled).p-focus,
.p-input-filled p-multiselect.ng-dirty.ng-invalid .p-multiselect:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

p-multiselect.ng-dirty.ng-invalid .p-multiselect:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger);
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 1.5rem;
}

.p-paginator {
  justify-content: flex-end;
}

.p-panel {
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.p-panel .p-panel-header,
.p-panel .p-panel-content,
.p-panel .p-panel-footer {
  border: 0 none;
}

.p-panel .p-panel-content {
  padding-top: 0;
}

.p-panel .p-panel-title {
  font-size: 1.25rem;
}

.p-component .p-panelmenu-header {
  margin-bottom: 5px;
}

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
  border-radius: 0;
  position: relative;
  transition: margin-bottom 225ms;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header .p-panelmenu-icon {
  order: 1;
  margin-left: auto;
  margin-right: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header.p-disabled {
  opacity: 1;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header.p-disabled .p-panelmenu-header-link > * {
  opacity: 0.38;
}

.p-progressbar {
  border-radius: 0;
}

.p-progressbar .p-progressbar-label {
  display: none;
}

.p-radiobutton {
  border-radius: 50%;
  transition: box-shadow 0.2s;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border: 2px solid #757575;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  border: 2px solid #757575;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled).p-focus {
  border-color: #19325A;
}

.p-radiobutton:not(.p-radiobutton-disabled):hover {
  box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.04);
}

.p-radiobutton:not(.p-radiobutton-disabled).p-radiobutton-focused {
  box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.12);
}

.p-radiobutton.p-radiobutton-checked:not(.p-radiobutton-disabled):hover {
  box-shadow: 0 0 1px 10px rgba(25, 50, 90, 0.04);
}

.p-radiobutton.p-radiobutton-checked:not(.p-radiobutton-disabled).p-radiobutton-focused {
  box-shadow: 0 0 1px 10px rgba(25, 50, 90, 0.12);
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #ffffff;
}

.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #ffffff;
}

.p-slider .p-slider-handle {
  transition: transform 0.2s, box-shadow 0.2s;
  transform: scale(0.7);
}

.p-slider .p-slider-handle:focus {
  box-shadow: 0 0 1px 10px rgba(51, 108, 204, 0.2);
}

.p-slider.p-slider-sliding .p-slider-handle {
  transform: scale(1);
}

.p-steps {
  padding: 1rem 0;
}

.p-steps ul {
  align-items: flex-start;
}

.p-steps .p-steps-item {
  justify-content: flex-start;
  flex-direction: row-reverse;
  align-items: baseline;
}

.p-steps .p-steps-item:before {
  position: static;
  left: auto;
  top: auto;
  margin-top: 0;
}

.p-steps .p-steps-item:last-child {
  flex-grow: 0;
}

.p-steps .p-steps-item:last-child:before {
  display: none;
}

.p-steps .p-steps-item .p-menuitem-link {
  flex: 1 1 auto;
  overflow: visible;
  padding: 0 0.5rem;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  background-color: #eee;
  color: #A4A4A4;
  font-size: 0.857rem;
  min-width: 1.714rem;
  height: 1.714rem;
  line-height: 1.714rem;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin: 0;
  padding-left: 0.5rem;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background-color: #19325A;
  color: #ffffff;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}

.p-steps .p-steps-item.p-disabled {
  opacity: 1;
}

.p-tabview .p-tabview-nav {
  position: relative;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  transition: background-color 0.2s;
  border-radius: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link > .p-ink {
  background-color: rgba(25, 50, 90, 0.16);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:focus {
  background-color: rgba(25, 50, 90, 0.12);
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  z-index: 1;
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #19325A;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-toolbar {
  border: 0 none;
}

.p-tooltip .p-tooltip-text {
  box-shadow: none;
  font-size: 0.875rem;
}

.p-tooltip .p-tooltip-arrow {
  display: none;
}

.p-treeselect .p-treeselect-label,
.p-treeselect .p-treeselect-trigger {
  background-image: none;
  background: transparent;
}

.p-treeselect .p-treeselect-label {
  border: 0 none;
}

.p-treeselect:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A, inset 0 0 0 1px #19325A;
}

.p-treeselect-item .p-ink {
  background-color: rgba(25, 50, 90, 0.16);
}

.p-input-filled .p-treeselect {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #f5f5f5 no-repeat;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}

.p-input-filled .p-treeselect .p-inputtext {
  background-image: none;
  background-color: transparent;
}

.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background-color: #ececec;
  border-color: transparent;
  background-image: linear-gradient(to bottom, #19325A, #19325A), linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
}

.p-input-filled .p-treeselect:not(.p-disabled).p-focus,
.p-input-filled .p-treeselect:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-color: #dcdcdc;
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}

.p-input-filled .p-treeselect .p-inputtext:enabled:hover,
.p-input-filled .p-treeselect .p-inputtext:enabled:focus {
  background-image: none;
  background: transparent;
}

.p-input-filled .p-treeselect .p-treeselect-label:hover {
  background-image: none;
  background: transparent;
}

.p-float-label .p-treeselect-label .p-treeselect-token {
  padding: 0.25rem 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.p-input-filled .p-float-label .p-treeselect .p-treeselect-label {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.p-input-filled .p-float-label .p-inputwrapper-filled .p-treeselect .p-treeselect-label {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.p-input-filled .p-float-label .p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.p-input-filled .p-float-label .p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-token .p-treeselect-token-icon {
  font-size: 75%;
}

.p-input-filled .p-treeselect.p-invalid {
  border-color: transparent;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled .p-treeselect.p-invalid:not(.p-disabled):hover {
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-input-filled .p-treeselect.p-invalid:not(.p-disabled).p-focus,
.p-input-filled .p-treeselect.p-invalid:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger));
}

.p-treeselect.p-invalid:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger), inset 0 0 0 1px var(--c-danger);
}

.p-treetable .p-sortable-column {
  outline: 0 none;
}

.p-treetable .p-sortable-column:focus {
  background-color: rgba(0, 0, 0, 0.03);
}

.p-treetable .p-treetable-tbody > tr {
  outline: 0 none;
}

.p-treetable .p-treetable-tbody > tr:not(.p-highlight):focus {
  background-color: rgba(0, 0, 0, 0.03);
}

.p-tabmenu .p-tabmenu-nav {
  position: relative;
}

.p-tabmenu .p-tabmenu-nav li .p-menuitem-link {
  transition: background-color 0.2s;
  border-radius: 0;
}

.p-tabmenu .p-tabmenu-nav li .p-menuitem-link > .p-ink {
  background-color: rgba(25, 50, 90, 0.16);
}

.p-tabmenu .p-tabmenu-nav li .p-menuitem-link:focus {
  background-color: rgba(25, 50, 90, 0.12);
}

.p-tabmenu .p-tabmenu-nav .p-tabmenu-ink-bar {
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #19325A;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  margin: 0.5rem 0;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  margin: 0 0.5rem;
}

.p-togglebutton.p-button:focus {
  background: #e0e0e1;
  border-color: #e0e0e1;
}

.p-togglebutton.p-button:focus.p-highlight {
  background: #d9d8d9;
  border-color: #d9d8d9;
}

pdf-viewer {
  display: block;
  height: 100vh;
}

iframe {
  width: 100% !important;
}

.p-carousel-item {
  display: flex !important;
  justify-content: center !important;
}

.textUppercase {
  text-transform: uppercase !important;
}

.textCapitalize {
  text-transform: capitalize !important;
}

.dialogModal {
  display: none !important;
}

.p-carousel-indicators {
  display: none !important;
}

.p-galleria-thumbnail-container {
  display: none !important;
}

.invalidField input {
  background-image: linear-gradient(to bottom, var(--c-danger), var(--c-danger)), linear-gradient(to bottom, var(--c-danger), var(--c-danger)) !important;
}
.invalidField label {
  color: var(--c-danger) !important;
}

.p-button {
  margin: 2px;
}

.top-fixed {
  width: 100%;
  height: 80px;
  padding-top: 5px;
  padding-bottom: 12px;
  background-color: #19325a;
}