.pin_container {
    margin: 0 auto;
    display: grid;
    padding-left: 18px;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-auto-rows: minmax(20px, auto);
    justify-content: center;

    & > * {
        display: grid;
    }
}
