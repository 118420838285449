.title-description {
    color: var(--c-nordsol);
    font-family: 'Work Sans';
    font-size: 2rem;
}

.title-description-card {
    color: var(--c-nordsol);
    font-size: 1.2rem;
    font-family: 'Work Sans';
    font-weight: 700;
    margin-bottom: 0.4rem;
}

.title-page {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--c-nordsol);
    margin-bottom: 16px;
}

.subtitle {
    font-family: 'Work Sans';
    font-size: 1.3rem;
    font-weight: 700;
    border-bottom: 1px solid var(--c-border-neutral);
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: var(--c-nordsol);
}

.subtitle-item {
    font-family: 'Work Sans';
    font-size: 1rem;
    font-weight: 600;
    border-bottom: 1px solid var(--c-border-neutral);
    width: 100%;
    margin-top: 0.5rem;
    color: var(--c-nordsol);
}

.title-column {
    color: var(--c-nordsol);
    font-weight: 600;
    font-size: 1rem;
    font-family: 'Work Sans';
}
