@import "./assets/sass/breakpoint";
@import "./assets/sass/global";
@import "./assets/sass/logo";
@import "./assets/sass/menu";
@import "./assets/sass/photos";
@import "./assets/sass/table";
@import "./assets/sass/title";
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "primeng/resources/primeng.css";

:root {
    --c-nordsol: #19325A;
    --c-nordsol900: #223962;
    --c-nordsol800: #364B75;
    --c-nordsol700: #4B5E8A;
    --c-nordsol600: #5F719F;
    --c-nordsol500: #7485B5;
    --c-nordsol400: #899ACB;
    --c-nordsol300: #9FB0E1;
    --c-nordsol200: #B5C5F8;
    --c-nordsol100: #CBDCFF;
    --c-nordsol50: #E2F2FF;

    --c-nordsol2: #0476D9;
    --c-nordsol2900: #003B93;
    --c-nordsol2800: #004CA8;
    --c-nordsol2700: #005FBE;
    --c-nordsol2600: #0072D5;
    --c-nordsol2500: #3486EC;
    --c-nordsol2400: #549BFF;
    --c-nordsol2300: #6FB0FF;
    --c-nordsol2200: #89C6FF;
    --c-nordsol2100: #A2DCFF;
    --c-nordsol250: #BBF3FF;

    --c-nordsol-green: #6CD8B4;
    --c-nordsol-green900: #00462C;
    --c-nordsol-green800: #00593D;
    --c-nordsol-green700: #006E50;
    --c-nordsol-green600: #008263;
    --c-nordsol-green500: #0b0d0c;
    --c-nordsol-green400: #3EAD8B;
    --c-nordsol-green300: #56C3A0;
    --c-nordsol-green200: #6EDAB6;
    --c-nordsol-green100: #85F1CC;
    --c-nordsol-green50: #9CFFE2;

    --c-nordsol3: #F5787D;
    --c-nordsol3900: #7A001E;
    --c-nordsol3800: #911C2F;
    --c-nordsol3700: #A93440;
    --c-nordsol3600: #C14A53;
    --c-nordsol3500: #D95F66;
    --c-nordsol3400: #F1757A;
    --c-nordsol3300: #FF8A8E;
    --c-nordsol3200: #FFA0A3;
    --c-nordsol3100: #FFB7B9;
    --c-nordsol350: #FFCECF;

    --c-nordsol-yellow: #dfa11bdc;
    --c-nordsol-orange: #fd7800;
    --c-nordsol-avatar: #e3893a;
    --c-nordsol-blue: #162f59;

    --c-font-dark: #091220;
    --c-font-light: #FEFEFE;
    --c-background-light: #F2F5F9;
    --c-disabled: #A4A4A4;
    --c-details: #EEEEEE;
    --c-empty-text: #9D9DAF;
    --c-input-border: #CBD5E1;
    --c-border-neutral: #CBD5E1;

    --c-pink: #FF5353;
    --c-danger: #D32F2F;
    --c-warning: #F5A400;
    --c-info: #4C7FD3;
    --c-success: #82C272;

    --bg-info: #bcd4fd;
    --bg-success: #E1F4D6;
    --bg-warning: #FEEFC3;
    --bg-disabled: #EDECEC;
    --bg-danger: #ffcdd2;

    --gradient-nordsol: linear-gradient(274.42deg, var(--c-nordsol) 0%, var(--c-nordsol2) 124.45%);
    --gradient-nordsol3: linear-gradient(274.42deg, var(--c-nordsol3) 0%, var(--c-nordsol-yellow) 124.45%);
    --card-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    --button-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}

:host ::ng-deep {
    .p-dropdown {
        border-radius: 1rem !important;
    }

    p-accordionTab {
        border-radius: 1rem !important;
    }

    .p-image-preview {
        max-width: 70vw !important;
    }

    .p-component-overlay {
        background-color: rgb(0 0 0 / 83%) !important;
    }

    .p-image-toolbar {
        top: 2rem !important;
        right: 2rem !important;
    }

    .pi {
        font-size: 1rem !important;
    }

    .p-accordion-header-text {
        font-weight: 700 !important;
        font-size: 1rem !important;
    }

    .p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
        height: 40px !important;
    }
}
