@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;700&display=swap");
@import '~@vime/core/themes/default.css';
@import '~@vime/core/themes/light.css';
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
@import "primeng/resources/primeng.css";
@media (max-width: 575.98px) {
  .box-background {
    padding: 0 !important;
    background-position: right top !important;
    align-content: flex-end !important;
  }
  .box-background .boxLogin {
    width: 100% !important;
    height: 75%;
    border-radius: 20px 20px 0 0 !important;
    padding: 5% 5%;
    align-self: flex-end;
  }
  .box-background .spanLogin {
    background-color: #fefefe;
    color: var(--c-nordsol);
    padding: 5px 0 !important;
    width: 100% !important;
    margin: 0 !important;
    position: relative !important;
  }
  .display-image {
    height: 100vw !important;
  }
  .text-bg::after {
    font-size: 13rem !important;
  }
  .n-height {
    height: 180px !important;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    display: none;
  }
  .p-steps .p-steps-item.p-highlight .p-steps-title {
    display: contents;
  }
}
@media (min-width: 576px) and (max-width: 767.98) {
  .text-bg::after {
    font-size: 15rem !important;
  }
  .n-height {
    height: 200px !important;
  }
}
@media (max-width: 767.98px) {
  .d-sm-label-none .p-button-label {
    display: none !important;
  }
  .boxLogin {
    width: 70% !important;
  }
  .box-details {
    border-radius: 15px 15px 0 0 !important;
  }
  .details-vehicles {
    height: auto !important;
  }
}
@media (min-width: 768px) {
  .boxLogin {
    width: 60% !important;
  }
  .n-height {
    height: 220px !important;
  }
  .text-bg::after {
    font-size: 18rem !important;
  }
}
@media (min-width: 992px) {
  .boxLogin {
    width: 50% !important;
  }
  .n-height {
    height: 300px !important;
  }
  .text-bg::after {
    font-size: 24rem !important;
  }
}
@media (min-width: 1279.98px) {
  .boxLogin {
    width: 45% !important;
  }
}
@media (min-width: 1400px) {
  .boxLogin {
    width: 40% !important;
  }
}
::ng-deep .p-card-content {
  padding: 0 !important;
}

html,
body {
  background-color: var(--c-background-light);
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 0.91rem;
  font-family: Lato, sans-serif;
  text-rendering: optimizelegibility;
  color: var(--c-font-dark) !important;
}
@media screen and (max-width: 768px) {
  html,
  body {
    width: auto;
    height: auto;
  }
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: transparent;
  }
}
.float-right {
  float: right;
}

.float-left {
  float: left;
}

.full-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.container {
  max-width: 100vw !important;
}

.container-page {
  height: 100%;
  padding-bottom: 0;
  overflow-y: auto;
}

.p-tabmenu {
  overflow-x: inherit !important;
}

.p-field {
  margin-bottom: 0;
  margin-top: 1rem;
}

.p-chip-text {
  white-space: nowrap !important;
}

.link-small {
  font-family: "Work sans", sans-serif;
  color: var(--c-nordsol2);
  cursor: pointer;
  margin-top: 6px !important;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  display: contents;
}
.link-small:hover {
  text-decoration: underline;
}

.body-form {
  background-color: #fefefe;
  border-radius: 8px 8px 0 0;
}

.box-background {
  background-color: var(--c-nordsol2);
  background-size: 34%;
  background-repeat: no-repeat;
  background-position: right bottom;
  height: 100vh !important;
  width: 100vw !important;
  align-items: center;
  align-content: center;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .box-background {
    height: 100vh !important;
    width: auto !important;
  }
}

.boxLogin {
  width: 40rem !important;
  height: 40rem !important;
  background-repeat: no-repeat;
  background-color: var(--c-background-light);
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: var(--card-shadow);
  -moz-box-shadow: var(--card-shadow);
  padding: 1.5rem 0.8rem;
  overflow-y: auto;
  border-radius: 32px;
  display: flex;
  background-position: left top;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .boxLogin {
    width: 100% !important;
    height: 100vh !important;
    position: relative;
  }
}

.p-status {
  padding: 0.25rem 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  border-radius: 4px;
  align-items: center;
  margin: auto;
  flex-wrap: nowrap;
}
.p-status.status-success {
  text-align: center;
  background-color: var(--bg-success);
  border: 1px solid var(--c-success);
  color: var(--c-success);
}
.p-status.status-notification {
  text-align: center;
  background-color: var(--c-nordsol3);
  border: 1px solid var(--c-nordsol3);
  color: #fff;
}
.p-status.status-information {
  text-align: center;
  background-color: var(--bg-info);
  border: 1px solid var(--c-info);
  color: var(--c-info);
}
.p-status.status-warning {
  text-align: center;
  background-color: var(--bg-warning) !important;
  border: 1px solid var(--c-warning);
  color: var(--c-warning) !important;
}
.p-status.status-error {
  text-align: center;
  background-color: var(--bg-danger);
  border: 1px solid var(--c-danger);
  color: var(--c-danger);
}
.p-status.status-basic-filling {
  text-align: center;
  border: 1px solid var(--c-disabled);
  color: var(--c-disabled);
}

.c-nordsol {
  color: var(--c-nordsol);
}

.c-success {
  color: var(--c-success) !important;
}

.c-danger {
  color: var(--c-danger) !important;
}

.c-warn {
  color: var(--c-warning) !important;
}

.c-disabled {
  color: var(--c-disabled);
}

.bg-filled {
  background-color: var(--c-disabled);
  color: #fff;
}

.bg-info {
  background-color: var(--c-info);
  color: #fff;
}

.bg-success {
  background-color: var(--c-success);
  color: #fff;
}

.bg-error {
  background-color: var(--c-danger);
  color: #fff;
}

.logo-dark {
  width: 200px;
  height: 130px;
  background-image: url("../images/logo_dark.png");
  background-repeat: no-repeat;
  background-size: contain;
  align-self: center;
  background-position: center;
}

.logo-nord {
  width: 200px;
  height: 130px;
  background-image: url("../images/logo-nordsol-erro.png");
  background-repeat: no-repeat;
  background-size: contain;
  align-self: center;
  background-position: center;
}

.p-breadcrumb {
  background: none !important;
  border: none !important;
  border-radius: 0 !important;
}

.custom-marker {
  display: flex;
  width: 2rem !important;
  height: 2rem !important;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}

::ng-deep .p-timeline-event-content,
::ng-deep .p-timeline-event-opposite {
  line-height: 1;
}

.default-card-photos {
  width: 100%;
  height: 160px;
  background-color: #AEC8ED;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px 5px 0 0;
}

.card-photos {
  height: 160px;
  background-size: cover;
  background-position: center center;
  border-radius: 10px 10px 0px 0px;
}
.card-photos img {
  width: 100%;
}

.photo-header {
  width: 120px;
  height: 96px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.photo-header--mini {
  width: 30px;
  height: 30px;
}
.photo-header--base {
  width: 120px;
  height: 96px;
}
.photo-header--maxi {
  width: 100px !important;
  height: 100px !important;
}

.default-position {
  width: 100%;
  height: 180px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px 5px 0 0;
}

.pin_container {
  margin: 0 auto;
  display: grid;
  padding-left: 18px;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-auto-rows: minmax(20px, auto);
  justify-content: center;
}
.pin_container > * {
  display: grid;
}

.title-description {
  color: var(--c-nordsol);
  font-family: "Work Sans";
  font-size: 2rem;
}

.title-description-card {
  color: var(--c-nordsol);
  font-size: 1.2rem;
  font-family: "Work Sans";
  font-weight: 700;
  margin-bottom: 0.4rem;
}

.title-page {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--c-nordsol);
  margin-bottom: 16px;
}

.subtitle {
  font-family: "Work Sans";
  font-size: 1.3rem;
  font-weight: 700;
  border-bottom: 1px solid var(--c-border-neutral);
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: var(--c-nordsol);
}

.subtitle-item {
  font-family: "Work Sans";
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 1px solid var(--c-border-neutral);
  width: 100%;
  margin-top: 0.5rem;
  color: var(--c-nordsol);
}

.title-column {
  color: var(--c-nordsol);
  font-weight: 600;
  font-size: 1rem;
  font-family: "Work Sans";
}

@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --c-nordsol: #19325A;
  --c-nordsol900: #223962;
  --c-nordsol800: #364B75;
  --c-nordsol700: #4B5E8A;
  --c-nordsol600: #5F719F;
  --c-nordsol500: #7485B5;
  --c-nordsol400: #899ACB;
  --c-nordsol300: #9FB0E1;
  --c-nordsol200: #B5C5F8;
  --c-nordsol100: #CBDCFF;
  --c-nordsol50: #E2F2FF;
  --c-nordsol2: #0476D9;
  --c-nordsol2900: #003B93;
  --c-nordsol2800: #004CA8;
  --c-nordsol2700: #005FBE;
  --c-nordsol2600: #0072D5;
  --c-nordsol2500: #3486EC;
  --c-nordsol2400: #549BFF;
  --c-nordsol2300: #6FB0FF;
  --c-nordsol2200: #89C6FF;
  --c-nordsol2100: #A2DCFF;
  --c-nordsol250: #BBF3FF;
  --c-nordsol-green: #6CD8B4;
  --c-nordsol-green900: #00462C;
  --c-nordsol-green800: #00593D;
  --c-nordsol-green700: #006E50;
  --c-nordsol-green600: #008263;
  --c-nordsol-green500: #0b0d0c;
  --c-nordsol-green400: #3EAD8B;
  --c-nordsol-green300: #56C3A0;
  --c-nordsol-green200: #6EDAB6;
  --c-nordsol-green100: #85F1CC;
  --c-nordsol-green50: #9CFFE2;
  --c-nordsol3: #F5787D;
  --c-nordsol3900: #7A001E;
  --c-nordsol3800: #911C2F;
  --c-nordsol3700: #A93440;
  --c-nordsol3600: #C14A53;
  --c-nordsol3500: #D95F66;
  --c-nordsol3400: #F1757A;
  --c-nordsol3300: #FF8A8E;
  --c-nordsol3200: #FFA0A3;
  --c-nordsol3100: #FFB7B9;
  --c-nordsol350: #FFCECF;
  --c-nordsol-yellow: #dfa11bdc;
  --c-nordsol-orange: #fd7800;
  --c-nordsol-avatar: #e3893a;
  --c-nordsol-blue: #162f59;
  --c-font-dark: #091220;
  --c-font-light: #FEFEFE;
  --c-background-light: #F2F5F9;
  --c-disabled: #A4A4A4;
  --c-details: #EEEEEE;
  --c-empty-text: #9D9DAF;
  --c-input-border: #CBD5E1;
  --c-border-neutral: #CBD5E1;
  --c-pink: #FF5353;
  --c-danger: #D32F2F;
  --c-warning: #F5A400;
  --c-info: #4C7FD3;
  --c-success: #82C272;
  --bg-info: #bcd4fd;
  --bg-success: #E1F4D6;
  --bg-warning: #FEEFC3;
  --bg-disabled: #EDECEC;
  --bg-danger: #ffcdd2;
  --gradient-nordsol: linear-gradient(274.42deg, var(--c-nordsol) 0%, var(--c-nordsol2) 124.45%);
  --gradient-nordsol3: linear-gradient(274.42deg, var(--c-nordsol3) 0%, var(--c-nordsol-yellow) 124.45%);
  --card-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  --button-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}

:host ::ng-deep .p-dropdown {
  border-radius: 1rem !important;
}
:host ::ng-deep p-accordionTab {
  border-radius: 1rem !important;
}
:host ::ng-deep .p-image-preview {
  max-width: 70vw !important;
}
:host ::ng-deep .p-component-overlay {
  background-color: rgba(0, 0, 0, 0.83) !important;
}
:host ::ng-deep .p-image-toolbar {
  top: 2rem !important;
  right: 2rem !important;
}
:host ::ng-deep .pi {
  font-size: 1rem !important;
}
:host ::ng-deep .p-accordion-header-text {
  font-weight: 700 !important;
  font-size: 1rem !important;
}
:host ::ng-deep .p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  height: 40px !important;
}