@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;700&display=swap');
@import 'src/assets/sass/variables';
@import '~@vime/core/themes/default.css';
@import '~@vime/core/themes/light.css';

::ng-deep .p-card-content {
    padding: 0 !important;
}

html,
body {
    background-color: var(--c-background-light);
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    font-size: 0.91rem;
    font-family: Lato, sans-serif;
    text-rendering: optimizelegibility;
    color: var(--c-font-dark) !important;

    @media screen and (max-width: 768px) {
        width: auto;
        height: auto;
    }
}

.p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
    from {
        background-color: transparent;
    }

    to {
        background-color: var(--maskbg);
    }
}

@keyframes p-component-overlay-leave-animation {
    from {
        background-color: var(--maskbg);
    }

    to {
        background-color: transparent;
    }
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.full-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-self: stretch;
}

.container {
    max-width: 100vw !important;
}

.container-page {
    height: 100%;
    padding-bottom: 0;
    overflow-y: auto;
}

.p-tabmenu {
    overflow-x: inherit !important;
}

.p-field {
    margin-bottom: 0;
    margin-top: 1rem;
}

.p-chip-text {
    white-space: nowrap !important;
}

.link-small {
    font-family: "Work sans", sans-serif;
    color: var(--c-nordsol2);
    cursor: pointer;
    margin-top: 6px !important;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    display: contents;

    &:hover {
        text-decoration: underline;
    }
}

.body-form {
    background-color: #fefefe;
    border-radius: 8px 8px 0 0;
}

.box-background {
    background-color: var(--c-nordsol2);
    background-size: 34%;
    background-repeat: no-repeat;
    background-position: right bottom;
    height: 100vh !important;
    width: 100vw !important;
    align-items: center;
    align-content: center;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        height: 100vh !important;
        width: auto !important;
    }
}

.boxLogin {
    width: 40rem !important;
    height: 40rem !important;
    background-repeat: no-repeat;
    background-color: var(--c-background-light);
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: var(--card-shadow);
    -moz-box-shadow: var(--card-shadow);
    padding: 1.5rem 0.8rem;
    overflow-y: auto;
    border-radius: 32px;
    display: flex;
    background-position: left top;
    justify-content: center;

    @media screen and (max-width: 768px) {
        width: 100% !important;
        height: 100vh !important;
        position: relative;
    }
}

.p-status {

    padding: 0.25rem 1rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    border-radius: 4px;
    align-items: center;
    margin: auto;
    flex-wrap: nowrap;

    &.status {
        &-success {
            text-align: center;
            background-color: var(--bg-success);
            border: 1px solid var(--c-success);
            color: var(--c-success);
        }

        &-notification {
            text-align: center;
            background-color: var(--c-nordsol3);
            border: 1px solid var(--c-nordsol3);
            color: #fff;
        }

        &-information {
            text-align: center;
            background-color: var(--bg-info);
            border: 1px solid var(--c-info);
            color: var(--c-info);
        }

        &-warning {
            text-align: center;
            background-color: var(--bg-warning) !important;
            border: 1px solid var(--c-warning);
            color: var(--c-warning) !important;
        }

        &-error {
            text-align: center;
            background-color: var(--bg-danger);
            border: 1px solid var(--c-danger);
            color: var(--c-danger);
        }

        &-basic-filling {
            text-align: center;
            border: 1px solid var(--c-disabled);
            color: var(--c-disabled);
        }
    }
}

.c-nordsol {
    color: var(--c-nordsol);
}

.c-success {
    color: var(--c-success) !important;
}

.c-danger {
    color: var(--c-danger) !important;
}

.c-warn {
    color: var(--c-warning) !important;
}

.c-disabled {
    color: var(--c-disabled);
}

.bg-filled {
    background-color: var(--c-disabled);
    color: #fff;
}

.bg-info {
    background-color: var(--c-info);
    color: #fff;
}

.bg-success {
    background-color: var(--c-success);
    color: #fff;
}

.bg-error {
    background-color: var(--c-danger);
    color: #fff;
}
